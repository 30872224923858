import { useState } from 'react';
import styles from './LandingFaq.module.scss';
import { stopPropagation } from '@/utils/events';

interface Props {
  header: string;
  body: string;
  number: string;
}

export const LandingFaqAccordion = (props: Props) => {
  const { header, body, number } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={styles.accordionItem}
      onClick={() => {
        setIsOpen((prev) => !prev);
      }}
      data-open={isOpen}
    >
      <div className={styles.accordionItemInner}>
        <div className={styles.accordionNumber}>{number}</div>
        <div className={styles.accordionBody}>
          <div className={styles.accordionHeaderTitle}>{header}</div>
          <div onClick={stopPropagation}>{isOpen && <div className={styles.accordionText}>{body}</div>}</div>
        </div>
      </div>
    </div>
  );
};
