import styles from './LandingFooter.module.scss';
import stylesCommon from '../../Landing.module.scss';
import clsx from 'clsx';
import { LandingLogo } from '../icons/LandingLogo';
import { LandingButton } from '../LandingButton';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import { LandingArrowUpIcon } from '../icons/LandingArrowUpIcon';

interface Props {
  openPhoneModal: () => void;
  openBidModal: () => void;
  scollToTop: () => void;
}

export const LandingFooter = (props: Props) => {
  const { openPhoneModal, openBidModal, scollToTop } = props;

  return (
    <div className={styles.footerBg}>
      <footer className={clsx(stylesCommon.wrapper)}>
        <div className={clsx(styles.footer)}>
          <div className={styles.footerLeft}>
            <LandingLogo />
            <div className={styles.footerTextLeft}>
              Цифровая платформа, которая помогает автоматизировать решение задач по охране труда
            </div>

            <a href="https://pmtds.ru/legal/pd.pdf" className={styles.legalLink} target="_blank" rel="noreferrer">
              Политика в отношении обработки персональных данных
            </a>
          </div>

          <div className={styles.footerInfo}>
            <div className={styles.footerInfoLinks}>
              <a
                href="https://pmtds.ru/legal/PMT-DS_Cycle_PO.pdf"
                className={styles.footerLink}
                target="_blank"
                rel="noreferrer"
              >
                Обеспечение поддержания жизненного цикла ПО
              </a>
              <a
                href="https://pmtds.ru/legal/UserManual.pdf"
                className={styles.footerLink}
                target="_blank"
                rel="noreferrer"
              >
                Руководство пользователя
              </a>
              <a
                href="https://pmtds.ru/legal/registration.pdf"
                className={styles.footerLink}
                target="_blank"
                rel="noreferrer"
              >
                Свидетельство о государственной регистрации
              </a>
            </div>
            <div className={styles.footerInfoContacts}>
              <a href="tel:74993504459" className={clsx(styles.footerLink, styles.footerLinkPhone)}>
                <span>+ 7 499 350 44 59</span>
              </a>
              <a href="mailto:support@pmtds.ru" className={clsx(styles.footerLink)}>
                <span>support@pmtds.ru</span>
              </a>
              <div className={clsx(styles.footerAddress)}>
                <span>
                  <span className="text-nowrap">111250 г. Москва,</span>{' '}
                  <span className="text-nowrap">ул. Лефортовский Вал,</span>{' '}
                  <span className="text-nowrap">д. 24, пом. 1/п</span>
                </span>
              </div>
            </div>

            <a href="https://pmtds.ru/legal/pd.pdf" className={styles.legalLinkMobile} target="_blank" rel="noreferrer">
              Политика в отношении обработки персональных данных
            </a>
          </div>

          <div className={styles.footerRight}>
            <div>
              <LandingButton className={styles.footerMoreButton} onClick={openBidModal} variant="fancy">
                Попробовать
                <LandingChevronRightIcon />
              </LandingButton>

              <LandingButton className={styles.footerCallButton} onClick={openPhoneModal} variant="secondary">
                Заказать звонок
                <LandingChevronRightIcon />
              </LandingButton>
            </div>

            <div className={styles.footerScollUpButton}>
              <div className={styles.footerScollUpButtonInner} onClick={scollToTop}>
                Наверх
                <LandingArrowUpIcon />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
