import './Toast.scss';

export const ToastError = (props: any) => {
  return (
    <div className="bs-toast toast fade show bg-danger">
      <div className="toast-header">
        <i className="bx bx-bell me-2"></i>
        <div className="me-auto fw-semibold">Ошибка</div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          onClick={props.closeToast}
        />
      </div>
      <div className="toast-body">{props.text}</div>
    </div>
  );
};

export const ToastSuccess = (props: any) => {
  return (
    <div className="bs-toast toast fade show bg-success">
      <div className="toast-header">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          onClick={props.closeToast}
        />
      </div>
      <div className="toast-body bg-white">{props.text}</div>
    </div>
  );
};
