export const LandingLoginUserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.761 18.232C17.872 16.638 16.464 15.445 14.823 14.797C15.985 13.93 16.75 12.557 16.75 10.999C16.75 8.38002 14.619 6.24902 12 6.24902C9.38101 6.24902 7.25001 8.38002 7.25001 10.999C7.25001 12.557 8.01401 13.93 9.17701 14.797C7.53501 15.445 6.12801 16.637 5.23901 18.232C5.03701 18.593 5.16701 19.05 5.52901 19.253C5.64501 19.317 5.77001 19.348 5.89401 19.348C6.15701 19.348 6.41201 19.209 6.55001 18.963C7.65501 16.982 9.74401 15.75 12.001 15.75C14.258 15.75 16.347 16.981 17.453 18.963C17.655 19.325 18.115 19.455 18.473 19.253C18.835 19.051 18.965 18.594 18.763 18.232H18.761ZM8.75001 11C8.75001 9.20802 10.208 7.75002 12 7.75002C13.792 7.75002 15.25 9.20802 15.25 11C15.25 12.792 13.792 14.25 12 14.25C10.208 14.25 8.75001 12.792 8.75001 11Z"
        fill="#6A859F"
      />
      <path
        d="M12 21.75C6.624 21.75 2.25 17.376 2.25 12C2.25 6.624 6.624 2.25 12 2.25C17.376 2.25 21.75 6.624 21.75 12C21.75 17.376 17.376 21.75 12 21.75ZM12 3.75C7.451 3.75 3.75 7.451 3.75 12C3.75 16.549 7.451 20.25 12 20.25C16.549 20.25 20.25 16.549 20.25 12C20.25 7.451 16.549 3.75 12 3.75Z"
        fill="#6A859F"
      />
    </svg>
  );
};
