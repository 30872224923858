export const LandingLoginButton = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <g clipPath="url(#clip0_1566_1483)">
        <path
          d="M7.97344 15.1443H1.99334C1.62656 15.1443 1.32891 14.8466 1.32891 14.4799V2.51974C1.32891 2.15296 1.62659 1.8553 1.99334 1.8553H7.97344C8.34087 1.8553 8.63787 1.5583 8.63787 1.19087C8.63787 0.82343 8.34087 0.526367 7.97344 0.526367H1.99334C0.894344 0.526367 0 1.42074 0 2.51974V14.4799C0 15.5789 0.894344 16.4732 1.99334 16.4732H7.97344C8.34087 16.4732 8.63787 16.1762 8.63787 15.8088C8.63787 15.4413 8.34087 15.1443 7.97344 15.1443Z"
          fill="#011135"
        />
        <path
          d="M15.802 8.02713L11.7622 4.04041C11.5017 3.7826 11.0805 3.78594 10.8226 4.04707C10.5648 4.30819 10.5675 4.72879 10.8293 4.9866L13.7163 7.83576H5.98011C5.61267 7.83576 5.31567 8.13276 5.31567 8.50019C5.31567 8.86763 5.61267 9.16466 5.98011 9.16466H13.7163L10.8293 12.0138C10.5675 12.2716 10.5655 12.6922 10.8226 12.9534C10.9529 13.0849 11.1243 13.1514 11.2957 13.1514C11.4645 13.1514 11.6333 13.0876 11.7622 12.96L15.802 8.97326C15.9283 8.84835 16 8.67823 16 8.50016C16 8.32216 15.929 8.15272 15.802 8.02713Z"
          fill="#011135"
        />
      </g>
      <defs>
        <clipPath id="clip0_1566_1483">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
