export const LandingFunctionalityIcon9 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1484_1047)">
        <path
          d="M26.8125 8.74121H1.40625C0.628969 8.74121 0 8.11224 0 7.33496C0 6.55768 0.628969 5.92871 1.40625 5.92871H26.8125C27.5898 5.92871 28.2188 6.55768 28.2188 7.33496C28.2188 8.11224 27.5898 8.74121 26.8125 8.74121Z"
          fill="url(#paint0_linear_1484_1047)"
        />
        <path
          d="M46.5938 8.74121H38.0625C37.2852 8.74121 36.6562 8.11224 36.6562 7.33496C36.6562 6.55768 37.2852 5.92871 38.0625 5.92871H46.5938C47.371 5.92871 48 6.55768 48 7.33496C48 8.11224 47.371 8.74121 46.5938 8.74121Z"
          fill="url(#paint1_linear_1484_1047)"
        />
        <path
          d="M9.9375 25.71H1.40625C0.628969 25.71 0 25.081 0 24.3037C0 23.5264 0.628969 22.8975 1.40625 22.8975H9.9375C10.7148 22.8975 11.3438 23.5264 11.3438 24.3037C11.3438 25.081 10.7148 25.71 9.9375 25.71Z"
          fill="url(#paint2_linear_1484_1047)"
        />
        <path
          d="M46.5938 25.71H21.1875C20.4102 25.71 19.7812 25.081 19.7812 24.3037C19.7812 23.5264 20.4102 22.8975 21.1875 22.8975H46.5938C47.371 22.8975 48 23.5264 48 24.3037C48 25.081 47.371 25.71 46.5938 25.71Z"
          fill="url(#paint3_linear_1484_1047)"
        />
        <path
          d="M26.8125 42.6787H1.40625C0.628969 42.6787 0 42.0497 0 41.2725C0 40.4952 0.628969 39.8662 1.40625 39.8662H26.8125C27.5898 39.8662 28.2188 40.4952 28.2188 41.2725C28.2188 42.0497 27.5898 42.6787 26.8125 42.6787Z"
          fill="url(#paint4_linear_1484_1047)"
        />
        <path
          d="M46.5938 42.6787H38.0625C37.2852 42.6787 36.6562 42.0497 36.6562 41.2725C36.6562 40.4952 37.2852 39.8662 38.0625 39.8662H46.5938C47.371 39.8662 48 40.4952 48 41.2725C48 42.0497 47.371 42.6787 46.5938 42.6787Z"
          fill="url(#paint5_linear_1484_1047)"
        />
        <path
          d="M15.5625 31.335C11.6857 31.335 8.53125 28.1806 8.53125 24.3037C8.53125 20.4269 11.6857 17.2725 15.5625 17.2725C19.4393 17.2725 22.5938 20.4269 22.5938 24.3037C22.5938 28.1806 19.4393 31.335 15.5625 31.335Z"
          fill="url(#paint6_linear_1484_1047)"
        />
        <path
          d="M26.8125 39.8662H24V42.6787H26.8125C27.5898 42.6787 28.2188 42.0497 28.2188 41.2725C28.2188 40.4952 27.5898 39.8662 26.8125 39.8662Z"
          fill="url(#paint7_linear_1484_1047)"
        />
        <path
          d="M46.5938 22.8975H24V25.71H46.5938C47.371 25.71 48 25.081 48 24.3037C48 23.5264 47.371 22.8975 46.5938 22.8975Z"
          fill="url(#paint8_linear_1484_1047)"
        />
        <path
          d="M26.8125 5.92871H24V8.74121H26.8125C27.5898 8.74121 28.2188 8.11224 28.2188 7.33496C28.2188 6.55768 27.5898 5.92871 26.8125 5.92871Z"
          fill="url(#paint9_linear_1484_1047)"
        />
        <path
          d="M32.4375 14.3662C28.5607 14.3662 25.4062 11.2118 25.4062 7.33496C25.4062 3.45812 28.5607 0.303711 32.4375 0.303711C36.3143 0.303711 39.4688 3.45812 39.4688 7.33496C39.4688 11.2118 36.3143 14.3662 32.4375 14.3662Z"
          fill="url(#paint10_linear_1484_1047)"
        />
        <path
          d="M32.4375 48.3037C28.5607 48.3037 25.4062 45.1493 25.4062 41.2725C25.4062 37.3956 28.5607 34.2412 32.4375 34.2412C36.3143 34.2412 39.4688 37.3956 39.4688 41.2725C39.4688 45.1493 36.3143 48.3037 32.4375 48.3037Z"
          fill="url(#paint11_linear_1484_1047)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1484_1047"
          x1="14.1094"
          y1="5.92871"
          x2="14.1094"
          y2="8.74121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_1047"
          x1="42.3281"
          y1="5.92871"
          x2="42.3281"
          y2="8.74121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_1047"
          x1="5.67188"
          y1="22.8975"
          x2="5.67188"
          y2="25.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1484_1047"
          x1="33.8906"
          y1="22.8975"
          x2="33.8906"
          y2="25.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1484_1047"
          x1="14.1094"
          y1="39.8662"
          x2="14.1094"
          y2="42.6787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1484_1047"
          x1="42.3281"
          y1="39.8662"
          x2="42.3281"
          y2="42.6787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1484_1047"
          x1="15.5625"
          y1="17.2725"
          x2="15.5625"
          y2="31.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1484_1047"
          x1="26.1094"
          y1="39.8662"
          x2="26.1094"
          y2="42.6787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1484_1047"
          x1="36"
          y1="22.8975"
          x2="36"
          y2="25.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1484_1047"
          x1="26.1094"
          y1="5.92871"
          x2="26.1094"
          y2="8.74121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1484_1047"
          x1="32.4375"
          y1="0.303711"
          x2="32.4375"
          y2="14.3662"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1484_1047"
          x1="32.4375"
          y1="34.2412"
          x2="32.4375"
          y2="48.3037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <clipPath id="clip0_1484_1047">
          <rect width="48" height="48" fill="white" transform="translate(0 0.303711)" />
        </clipPath>
      </defs>
    </svg>
  );
};
