import styles from './LandingFaq.module.scss';
import stylesCommon from '../../Landing.module.scss';
import { LandingFaqAccordion } from './LandingFaqAccordion';
import clsx from 'clsx';
import { LANDING_SECTION_CLASS } from '@/landing/util';

interface Props {
  openPhoneModal: () => void;
}

export const LandingFaq = (props: Props) => {
  return (
    <div className={clsx(styles.faq, LANDING_SECTION_CLASS)} id="landing-faq">
      <div className={clsx(stylesCommon.wrapper, styles.faqWrapper)}>
        <div className={styles.faqInner}>
          <div className={styles.faqTop}>
            <h3 className={styles.faqHeader}>часто задаваемые вопросы</h3>
          </div>
          <div className={styles.accordion}>
            <LandingFaqAccordion
              number="01"
              header="Сколько времени занимает установка системы?"
              body="Вам не нужно ни устанавливать систему, ни настраивать ее. Это облачная программа, с ней можно работать откуда угодно. "
            />
            <LandingFaqAccordion
              number="02"
              header="Гарантируете ли вы конфиденциальность загруженных на ваш Сервис сведений?"
              body="Сервер, который мы используем, расположен на территории Российской Федерации. Наш программный комплекс зарегистрирован в реестре программ для ЭВМ Федеральной службы по интеллектуальной собственности (регистрационный № 2023680619 от 03.10.2023). Хранение персональных данных заказчиков организовано в строгом соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (регистрационный номер в реестре операторов, осуществляющих обработку персональных данных:  77-23-153020 от 06.10.2023). "
            />
            <LandingFaqAccordion
              number="03"
              header="Мы аутсорсинговая компания, у нас несколько организаций-клиентов. Нужен ли отдельный личный кабинет для каждого?"
              body="Нет, всех клиентов можно вести в одном личном кабинете, при этом информацию по каждому юридическому лицу можно просматривать отдельно."
            />
            <LandingFaqAccordion
              number="04"
              header="Сколько сотрудников может иметь доступ к одному личному кабинету? "
              body="Вы сами определяете, кому дать доступ к личному кабинету. При этом каждому пользователю можно назначить роль, которой будет соответствовать определенный функционал. Это позволяет разделить работу, назначить ответственных и делегировать им необходимые функции. "
            />
          </div>
        </div>
      </div>
    </div>
  );
};
