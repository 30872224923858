import { ThreeDotsSpinner } from '@/components/ThreeDotsSpinner';

export const LoadingIndicator = () => {
  return (
    <div className="pb-3 pt-3 d-flex justify-content-center align-items-center gap-2">
      <ThreeDotsSpinner />
      <span className="font--12-normal text-grey-400">Подождите идет загрузка</span>
    </div>
  );
};
