import { LANDING_SECTION_CLASS } from '@/landing/util';
// import stylesCommon from '../../Landing.module.scss';
import clsx from 'clsx';

import styles from './LangingVideo.module.scss';

export const LangingVideo = () => {
  return (
    <div className={clsx(styles.landingVideoWrapper, LANDING_SECTION_CLASS)}>
      <div>
        <video src="/product.mp4" className={styles.landingVideo} controls />
      </div>
    </div>
  );
};
