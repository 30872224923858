export const LandingHelpIcon2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1473_7185)">
        <path
          d="M1.80286 9.13185C2.51422 6.59932 4.13361 4.43281 6.36273 3.03169C8.42725 1.73402 10.7959 1.19642 13.2129 1.47694C14.965 1.68019 16.6417 2.32234 18.0738 3.32155L16.9645 3.35981L17.0129 4.76497L20.6655 4.63936L20.5334 1.03181L19.1281 1.08327L19.1759 2.3835C17.4908 1.12941 15.4808 0.32466 13.3749 0.080214C12.0471 -0.0737776 10.7146 -0.00676102 9.41473 0.279799C8.06946 0.57643 6.79083 1.10176 5.61438 1.84114C3.08918 3.4283 1.25483 5.88264 0.449162 8.75172C-0.350826 11.6001 -0.076717 14.6256 1.22077 17.2707L2.48328 16.6514C1.33832 14.3174 1.09662 11.6468 1.80286 9.13185Z"
          fill="currentColor"
        />
        <path
          d="M22.4433 6.09526L21.2198 6.78813C22.563 9.16063 22.9364 11.9274 22.2712 14.5785C21.6038 17.238 19.9573 19.5064 17.6349 20.9662C15.4908 22.3138 12.9332 22.8479 10.4336 22.4702C8.48865 22.1763 6.67206 21.3485 5.18597 20.0981L6.49664 20.0528L6.4483 18.6475L2.79553 18.7733L2.92773 22.3808L4.33289 22.3292L4.29077 21.1824C5.97223 22.594 8.02594 23.5284 10.2236 23.8605C10.817 23.9502 11.4134 23.9945 12.0077 23.9945C14.2499 23.9945 16.4631 23.3636 18.3833 22.1565C21.014 20.5031 22.8789 17.9334 23.6349 14.9208C24.3886 11.9178 23.9654 8.78343 22.4433 6.09526Z"
          fill="currentColor"
        />
        <path
          d="M12.0574 9.58293C10.8163 9.58293 9.80652 10.5926 9.80652 11.8338C9.80652 13.0749 10.8163 14.0846 12.0574 14.0846C13.2985 14.0846 14.3082 13.0749 14.3082 11.8338C14.3082 10.5926 13.2985 9.58293 12.0574 9.58293Z"
          fill="currentColor"
        />
        <path
          d="M19.4565 13.6813V9.98601L18.0319 9.70952C17.9559 9.494 17.8682 9.28251 17.7693 9.07634L18.5827 7.87352L15.9725 5.25804L14.7682 6.06901C14.5582 5.96776 14.3429 5.87822 14.1232 5.80077L13.8467 4.37621H10.1514L9.87494 5.80077C9.65942 5.87675 9.44794 5.96446 9.24176 6.06334L8.03894 5.24998L5.42346 7.86015L6.23444 9.06444C6.13318 9.27446 6.04364 9.48979 5.96619 9.70933L4.54163 9.98601V13.6813L5.96619 13.9577C6.04217 14.1733 6.12988 14.3846 6.22876 14.5909L5.4154 15.7936L8.02557 18.4092L9.23004 17.5982C9.43988 17.6995 9.65521 17.789 9.87494 17.8665L10.1514 19.2911H13.8467L14.1232 17.8665C14.3387 17.7905 14.5502 17.7028 14.7565 17.6039L15.9592 18.4173L18.5748 15.8071L17.7638 14.6028C17.8649 14.3928 17.9546 14.1775 18.0321 13.9577L19.4565 13.6813ZM12.0576 15.4905C10.041 15.4905 8.40057 13.8501 8.40057 11.8335C8.40057 9.81718 10.041 8.17674 12.0576 8.17674C14.0739 8.17674 15.7144 9.81718 15.7144 11.8335C15.7144 13.8501 14.0739 15.4905 12.0576 15.4905Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1473_7185">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
