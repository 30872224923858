export const HelpsWithIcon1 = () => {
  return (
    <svg width="82" height="91" viewBox="0 0 82 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="1">
        <path
          id="Vector"
          d="M69.8926 12.5792H53.0198C52.6353 12.5792 52.2667 12.7314 51.995 13.0024L34.9464 29.9937H18.0736C17.6891 29.9937 17.3205 30.1459 17.0487 30.4169L0.424847 46.9847C0.152891 47.2559 0 47.6236 0 48.0069V73.5296L81.5674 82.2369V0.943848L69.8926 12.5792Z"
          fill="url(#paint0_linear_580_14591)"
        />
        <path
          id="Vector_2"
          d="M69.8928 38.7005H52.4196L34.9464 56.115H17.4732L0 73.5295V89.4984C0 90.2966 0.64906 90.9437 1.44966 90.9437H81.5674V27.0652L69.8928 38.7005Z"
          fill="url(#paint1_linear_580_14591)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_580_14591"
          x1="40.7837"
          y1="0.943848"
          x2="40.7837"
          y2="82.2369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_580_14591"
          x1="40.7837"
          y1="27.0652"
          x2="40.7837"
          y2="90.9437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
