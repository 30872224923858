export const HelpsWithIcon4 = () => {
  return (
    <svg width="104" height="105" viewBox="0 0 104 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_580_14457)">
        <path
          d="M39.3478 47.6008L41.1212 49.3743C43.473 51.9528 44.7418 55.2317 44.7418 58.6552V61.1975C52.0777 61.9254 57.8062 68.1143 57.8064 75.642C57.8064 83.1695 52.0754 89.3581 44.7395 90.086C44.2628 90.1331 43.7787 90.1578 43.2895 90.1578C35.2725 90.1578 28.7733 83.6587 28.7733 75.6416C28.7733 68.1143 34.5028 61.925 41.8386 61.197V58.6552C41.8386 55.9561 40.8379 53.3719 39.0219 51.3787L37.2952 49.6534L39.3478 47.6008Z"
          fill="url(#paint0_linear_580_14457)"
        />
        <g clipPath="url(#clip1_580_14457)">
          <path
            d="M40.6622 83.0155C40.0378 82.9902 39.5484 82.4755 39.5512 81.8511C39.5428 81.2745 39.5597 80.6727 39.5484 80.0905C39.3572 80.0989 39.1265 80.0989 38.9268 80.0933C37.8693 80.1045 37.3856 78.7714 38.145 78.0711C38.3728 77.8602 38.6372 77.7561 38.9268 77.7617C39.1068 77.7645 39.3684 77.7617 39.5512 77.7617C39.5484 77.5761 39.5512 77.3736 39.5512 77.1795C39.3318 77.1739 39.0928 77.1739 38.8847 77.1852C38.2856 77.1852 37.7765 76.6508 37.7709 76.018C37.7568 75.4217 38.2743 74.8367 38.8903 74.8508C39.104 74.8592 39.3375 74.8564 39.5484 74.8508C39.5568 73.2139 39.5456 71.4449 39.5512 69.8164C39.5372 69.0767 40.0687 68.5902 40.7493 68.6211H40.9659C41.8462 68.6211 42.7265 68.6155 43.6068 68.6155C44.535 68.6155 45.2522 68.6211 45.93 68.6324C46.9875 68.652 47.9212 69.0514 48.7031 69.8249C51.3047 72.2324 49.8422 76.7155 46.3406 77.1317C45.7893 77.1992 45.2212 77.1795 44.6812 77.1795C43.8825 77.1936 42.7097 77.1767 41.8968 77.1852C41.8968 77.3764 41.894 77.5705 41.8997 77.7617L41.9081 77.7645C41.9418 77.7702 41.984 77.7617 42.0178 77.7617H45.8597C46.5037 77.7477 47.0381 78.2595 47.0493 78.9036V78.9711C47.0409 79.5224 46.5965 80.0961 45.8709 80.0961C45.3365 80.0905 44.8022 80.0877 44.2678 80.0905C43.4775 80.0905 42.69 80.0933 41.8997 80.0933V81.2633C41.8997 81.4855 41.8997 81.7133 41.8912 81.9383C41.8659 82.602 41.2528 83.0155 40.6987 83.0155H40.6622ZM41.9165 74.8536C42.9993 74.8508 44.594 74.862 45.66 74.8508C46.8862 74.9014 47.9803 73.9311 47.82 72.7077C47.7075 71.7458 46.9622 71.0202 46.0509 70.9836C45.2578 70.9499 44.4478 70.947 43.6884 70.947C43.199 70.9527 42.375 70.947 41.894 70.9442C41.9025 71.3492 41.894 74.3811 41.8968 74.8536H41.9165Z"
            fill="url(#paint1_linear_580_14457)"
          />
        </g>
        <path
          d="M86.8382 79.9963H69.4188C67.8155 79.9963 66.5156 81.2961 66.5156 82.8995L68.5642 84.0511H88.379L86.8382 79.9963Z"
          fill="url(#paint2_linear_580_14457)"
        />
        <path
          d="M86.8382 79.9963C86.8382 81.5998 85.5383 82.8995 83.935 82.8995L66.5156 82.8995V88.7059H89.7416V82.8995C89.7416 81.2961 88.4415 79.9963 86.8382 79.9963Z"
          fill="url(#paint3_linear_580_14457)"
        />
        <path
          d="M63.6124 85.8035H92.6448C94.2481 85.8035 95.5484 87.1032 95.5484 88.7067V91.6099L94.2203 92.2739V93.0615H62.1608C61.3591 93.0615 60.7092 92.4116 60.7092 91.6099V88.7067C60.7092 87.1034 62.009 85.8035 63.6124 85.8035Z"
          fill="#476BFA"
        />
        <path
          d="M60.7092 91.6094H95.5478V95.9642C95.5478 96.7659 94.898 97.4158 94.0962 97.4158H62.1608C61.3591 97.4158 60.7092 96.7659 60.7092 95.9642V91.6094Z"
          fill="#1943E8"
        />
        <path d="M37.0197 47.275L33.9362 44.1916L43.1742 34.9536L46.8598 37.4349L37.0197 47.275Z" fill="#476BFA" />
        <path
          d="M46.2535 31.8743L43.1742 34.9536C44.308 36.0874 44.308 37.9255 43.1742 39.0593L35.9891 46.2444L38.0421 48.2973L50.3594 35.98L46.2535 31.8743Z"
          fill="#476BFA"
        />
        <path
          d="M47.2807 10.3193L53.4394 16.478L53.5036 18.7087L63.64 28.8451L65.7568 28.7954L71.9146 34.9535L59.5972 47.2708L53.4385 41.1122L53.159 38.8093L43.309 28.9592L41.1212 28.7948L34.9625 22.6362L47.2807 10.3193Z"
          fill="url(#paint4_linear_580_14457)"
        />
        <path
          d="M25.7247 50.3501L9.30166 66.7732L9.51084 69.2478L11.5524 71.2895C12.686 72.4232 14.5243 72.4233 15.6581 71.2895L32.3422 54.6054L25.7247 50.3501Z"
          fill="#476BFA"
        />
        <path
          d="M25.7247 50.3501L29.8304 54.4558L15.4602 68.8261C14.3264 69.9599 12.488 69.9598 11.3544 68.8261L9.30166 66.7732C8.16787 67.907 8.16787 69.7453 9.30148 70.8789L11.3544 72.9319C12.488 74.0655 14.3264 74.0657 15.4602 72.9319L31.8832 56.5088L35.5966 49.9578L30.8568 45.218L25.7247 50.3501Z"
          fill="#476BFA"
        />
        <path d="M34.9645 49.3166L37.0171 51.3692L31.8857 56.5006L29.8331 54.448L34.9645 49.3166Z" fill="#476BFA" />
        <path
          d="M73.9676 32.9006C72.8338 31.7668 70.9956 31.7668 69.8618 32.9006L59.5974 43.165L61.6349 47.6285L73.9523 35.3111L73.9676 32.9006Z"
          fill="url(#paint5_linear_580_14457)"
        />
        <path
          d="M73.9676 32.9006L63.7032 43.165C62.5694 44.2988 60.7311 44.2986 59.5974 43.165L57.5445 45.218C56.4107 46.3518 56.4109 48.1901 57.5445 49.3237C58.6781 50.4573 60.5164 50.4575 61.6502 49.3237L73.9676 37.0064C75.1015 35.8727 75.1013 34.0344 73.9676 32.9006Z"
          fill="url(#paint6_linear_580_14457)"
        />
        <path
          d="M53.4838 38.9048L43.4047 28.8256L43.1742 26.7419L53.4388 16.4775L65.7561 28.7949L55.4916 39.0593L53.4838 38.9048Z"
          fill="url(#paint7_linear_580_14457)"
        />
        <path
          d="M43.1734 26.7359L55.4889 39.0514L53.4364 41.104L41.1208 28.7884L43.1734 26.7359Z"
          fill="url(#paint8_linear_580_14457)"
        />
        <path
          d="M33.936 42.1384C32.8022 41.0046 30.9641 41.0046 29.8303 42.1384L30.2328 44.6314L37.2577 51.6563L39.0683 47.2707L33.936 42.1384Z"
          fill="url(#paint9_linear_580_14457)"
        />
        <path
          d="M40.0945 48.2971L39.0683 47.2707C37.9345 48.4045 36.096 48.4043 34.9624 47.2707L29.8303 42.1384C28.6965 43.2722 28.6963 45.1105 29.8301 46.2441L35.9888 52.4028C37.1226 53.5364 38.9607 53.5366 40.0945 52.4028C41.2285 51.2692 41.2283 49.4309 40.0945 48.2971Z"
          fill="url(#paint10_linear_580_14457)"
        />
        <path
          d="M49.3328 8.26587C48.199 7.13208 46.3609 7.13208 45.2271 8.26587L34.9627 18.5303L37.0002 22.9937L49.3175 10.6764L49.3328 8.26587Z"
          fill="url(#paint11_linear_580_14457)"
        />
        <path
          d="M49.3328 8.26587L39.0684 18.5303C37.9346 19.6641 36.0963 19.6639 34.9627 18.5303L32.9097 20.5832C31.7759 21.717 31.7761 23.5553 32.9097 24.6889C34.0435 25.8227 35.8817 25.8227 37.0154 24.6889L49.3328 12.3716C50.4668 11.238 50.4666 9.39966 49.3328 8.26587Z"
          fill="url(#paint12_linear_580_14457)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_580_14457"
          x1="43.2899"
          y1="47.6008"
          x2="43.2899"
          y2="90.1578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_580_14457"
          x1="43.8993"
          y1="68.6155"
          x2="43.8993"
          y2="83.0155"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_580_14457"
          x1="78.1286"
          y1="79.9963"
          x2="78.1286"
          y2="88.7059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_580_14457"
          x1="78.1286"
          y1="79.9963"
          x2="78.1286"
          y2="88.7059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_580_14457"
          x1="53.4385"
          y1="10.3193"
          x2="53.4385"
          y2="47.2708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_580_14457"
          x1="65.7561"
          y1="32.0503"
          x2="65.7561"
          y2="50.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_580_14457"
          x1="65.7561"
          y1="32.0503"
          x2="65.7561"
          y2="50.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_580_14457"
          x1="53.4385"
          y1="16.4775"
          x2="53.4385"
          y2="41.104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_580_14457"
          x1="53.4385"
          y1="16.4775"
          x2="53.4385"
          y2="41.104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_580_14457"
          x1="34.9624"
          y1="41.2881"
          x2="34.9624"
          y2="53.2531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_580_14457"
          x1="34.9624"
          y1="41.2881"
          x2="34.9624"
          y2="53.2531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_580_14457"
          x1="41.1213"
          y1="7.41553"
          x2="41.1213"
          y2="25.5393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_580_14457"
          x1="41.1213"
          y1="7.41553"
          x2="41.1213"
          y2="25.5393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <clipPath id="clip0_580_14457">
          <rect width="90" height="90" fill="white" transform="translate(7 7.41577)" />
        </clipPath>
        <clipPath id="clip1_580_14457">
          <rect width="14.4" height="14.4" fill="white" transform="translate(36.6993 68.6155)" />
        </clipPath>
      </defs>
    </svg>
  );
};
