export const HelpsWithIcon2 = () => {
  return (
    <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="worker 1" clipPath="url(#clip0_580_14711)">
        <g id="layer1">
          <path
            id="path16260-1"
            d="M18.3457 53.5684C8.24223 53.5684 0.0644212 61.746 0.0644632 71.8496V88.7162C0.0630506 89.0892 0.209827 89.4476 0.472511 89.7123C0.735194 89.977 1.09227 90.1265 1.46522 90.128H60.5332C60.9062 90.1265 61.2632 89.977 61.5259 89.7123C61.7886 89.4476 61.9354 89.0892 61.934 88.7162V71.8496C61.934 61.746 53.7562 53.5683 43.6527 53.5684H18.3457Z"
            fill="url(#paint0_linear_580_14711)"
          />
          <path
            id="path16263-7"
            d="M0.0644636 81.6907V88.7164C0.0614963 89.493 0.688599 90.1251 1.46522 90.1281H60.5332C61.3098 90.1251 61.9369 89.493 61.934 88.7164V81.6907H0.0644636Z"
            fill="#476BFA"
          />
          <g id="Group">
            <path
              id="path5354"
              d="M22.5643 45.1238V54.9758C22.5644 55.35 22.7137 55.7089 22.9791 55.9728C22.9791 55.9728 26.1581 59.1917 31.0018 59.1918C35.8456 59.1919 39.0273 55.9728 39.0273 55.9728C39.2887 55.7071 39.4339 55.3485 39.4311 54.9758V45.1238H22.5643Z"
              fill="#476BFA"
            />
            <path
              id="path16026-2"
              d="M44.6187 31.0459C43.7913 31.0831 42.9606 31.3583 42.2484 31.8616V42.3152C42.2475 42.3152 42.2494 42.3152 42.2484 42.3152C45.7379 42.3152 49.2797 39.6141 49.2797 35.2866C49.28 35.2689 49.28 35.2512 49.2797 35.2335C49.2106 33.1559 47.6739 31.6359 45.959 31.185C45.5303 31.0723 45.0757 31.0251 44.6187 31.0459Z"
              fill="#476BFA"
            />
            <path
              id="path16030-2"
              d="M17.3808 31.0462C16.9238 31.0249 16.4692 31.0727 16.0405 31.1865C14.3256 31.6372 12.7889 33.1574 12.7199 35.235C12.7195 35.2527 12.7195 35.2703 12.7199 35.288C12.7199 39.6156 16.2561 42.3166 19.7456 42.3166C19.7447 42.3166 19.7465 42.3166 19.7456 42.3166L19.7484 31.8521C19.0377 31.3509 18.2065 31.084 17.3808 31.0462Z"
              fill="#476BFA"
            />
          </g>
          <path
            id="path16017-8"
            d="M18.3448 29.6616V38.0991C18.3448 45.0863 24.0111 50.7554 30.9984 50.7554C37.9856 50.7554 43.6519 45.0863 43.6519 38.0991V29.6616H18.3448Z"
            fill="#476BFA"
          />
          <path
            id="path16045-6"
            d="M30.9991 8.56641C22.4537 8.56641 15.5317 15.4884 15.5317 24.0338H46.4665C46.4665 15.4884 39.5445 8.56641 30.9991 8.56641Z"
            fill="#1943E8"
          />
          <path
            id="path16041-8"
            d="M16.9331 31.0615C14.6202 31.0615 12.7185 29.1598 12.7185 26.8469C12.7185 24.5341 14.6202 22.6323 16.9331 22.6323H45.065C47.3779 22.6323 49.2796 24.5341 49.2796 26.8469C49.2796 29.1598 47.3779 31.0615 45.065 31.0615H16.9331Z"
            fill="#1943E8"
          />
          <path
            id="path16049-37"
            d="M29.5873 5.75342C28.4245 5.75342 27.3462 6.20081 26.5353 6.96781C25.7244 7.73465 25.1813 8.93395 25.3934 10.2068L26.7948 18.6358C26.8479 18.9663 27.0171 19.2672 27.2721 19.4841C27.5271 19.701 27.8511 19.8198 28.1859 19.8192H33.8122C34.1452 19.8173 34.4667 19.6974 34.7196 19.4807C34.9724 19.264 35.1401 18.9646 35.1929 18.6358L36.5943 10.2483C36.8522 8.95878 36.2925 7.73555 35.4732 6.96781C34.6538 6.20006 33.566 5.75342 32.4005 5.75342H29.5873Z"
            fill="url(#paint1_linear_580_14711)"
          />
          <path
            id="rect5359"
            d="M18.3429 53.5669C16.8879 53.5669 15.4806 53.7546 14.1241 54.0751V69.0357H21.1554V53.5669H18.3429ZM40.8429 53.5669V69.0357H47.8741V54.0751C46.5161 53.7535 45.1067 53.5669 43.6499 53.5669H40.8429Z"
            fill="#1943E8"
          />
          <path
            id="path5377"
            d="M15.5662 67.6294C15.0724 67.6308 14.6158 67.8919 14.3632 68.316L10.106 75.3748C9.97547 75.5943 9.90713 75.8446 9.90822 76.0998V90.128H52.0957V76.0998C52.0943 75.8436 52.0232 75.5933 51.8897 75.3748L47.6435 68.316C47.3903 67.8908 46.9324 67.6303 46.4377 67.6294H15.5662Z"
            fill="#1943E8"
          />
        </g>
        <g id="Layer 2">
          <path
            id="Vector"
            d="M86.2925 29.5483H74.6427L84.5642 7.22128C84.8259 6.63449 84.9367 5.99159 84.8866 5.35105C84.8364 4.71051 84.6269 4.09267 84.2771 3.55375C83.9273 3.01483 83.4483 2.57193 82.8837 2.26534C82.3191 1.95876 81.6867 1.79822 81.0442 1.79834H59.8614C59.053 1.79801 58.265 2.05198 57.609 2.52429C56.9529 2.9966 56.4621 3.66331 56.2059 4.43003L56.2028 4.43311L44.9024 38.3467C44.7085 38.926 44.6551 39.543 44.7466 40.147C44.8381 40.7509 45.0718 41.3245 45.4286 41.8203C45.7853 42.3162 46.2548 42.7201 46.7984 42.9988C47.3419 43.2776 47.944 43.4231 48.5548 43.4233H62.0354L57.871 62.1537C57.6724 62.9974 57.7684 63.8837 58.143 64.6654C58.5177 65.447 59.1485 66.0769 59.9307 66.4505C60.4678 66.7177 61.0592 66.8578 61.6591 66.86C62.2215 66.8579 62.7764 66.7313 63.284 66.4892C63.7917 66.2471 64.2393 65.8956 64.5949 65.4598L89.2525 35.8701C89.7216 35.3074 90.0206 34.6227 90.1145 33.8962C90.2084 33.1697 90.0933 32.4314 89.7827 31.768C89.472 31.1045 88.9787 30.5434 88.3606 30.1503C87.7424 29.7571 87.0251 29.5483 86.2925 29.5483Z"
            fill="url(#paint2_linear_580_14711)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_580_14711"
          x1="30.9992"
          y1="53.5684"
          x2="30.9992"
          y2="90.128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_580_14711"
          x1="31.0025"
          y1="5.75342"
          x2="31.0025"
          y2="19.8192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_580_14711"
          x1="67.4247"
          y1="1.79834"
          x2="67.4247"
          y2="66.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_580_14711">
          <rect width="91" height="90" fill="white" transform="translate(0 0.943848)" />
        </clipPath>
      </defs>
    </svg>
  );
};
