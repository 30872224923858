export const LandingArrowUpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="38" viewBox="0 0 37 38" fill="none">
      <path
        d="M19.872 7.41451L18.9263 6.02368L18.9255 32.4746H18.1855L18.1863 6.18944L17.4333 7.49961L16.7917 7.13109L18.5241 4.11596L20.484 6.99826L19.872 7.41451Z"
        fill="white"
      />
    </svg>
  );
};
