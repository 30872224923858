export const HelpsWithIcon3 = () => {
  return (
    <svg width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="fracture 1" clipPath="url(#clip0_580_14494)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M51.8907 25.6054C51.7765 25.6054 51.6611 25.5907 51.5479 25.5603L48.0761 24.63C47.3696 24.4409 46.9504 23.7147 47.1397 23.0081C47.3288 22.3016 48.0548 21.8817 48.7616 22.0717L50.0178 22.4082L49.6811 21.1522C49.4918 20.4457 49.9111 19.7196 50.6175 19.5303C51.3242 19.3404 52.0503 19.7602 52.2395 20.4666L53.1699 23.9383C53.2924 24.3954 53.1618 24.883 52.8273 25.2175C52.5756 25.4692 52.2374 25.6054 51.8907 25.6054Z"
              fill="url(#paint0_linear_580_14494)"
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_2"
              d="M9.76388 82.5043C9.42497 82.5043 9.08606 82.375 8.82731 82.1166C8.31017 81.5992 8.31017 80.7608 8.82731 80.2434L18.1086 70.9622C18.6259 70.4452 19.4644 70.4452 19.9817 70.9622C20.4988 71.4795 20.4988 72.318 19.9817 72.8353L10.7004 82.1166C10.4417 82.375 10.1028 82.5043 9.76388 82.5043Z"
              fill="url(#paint1_linear_580_14494)"
            />
          </g>
          <g id="Group_4">
            <path
              id="Vector_3"
              d="M69.8028 6.03149L39.4383 36.3959C37.9155 37.9187 36.7666 39.7738 36.082 41.8156L31.8692 54.378C31.6164 55.1316 31.1924 55.8163 30.6305 56.3782L16.5057 70.503C16.1608 70.8478 16.1608 71.4068 16.5057 71.7515L19.1924 74.4382C19.5373 74.7831 20.0962 74.7831 20.4409 74.4382L34.5655 60.3136C35.1275 59.7516 35.8121 59.3277 36.5657 59.0749L49.1283 54.8619C51.1592 54.1808 53.0334 53.0201 54.548 51.5056L84.9124 21.1411L69.8028 6.03149ZM50.8024 47.7597C49.8639 48.6982 48.7025 49.4171 47.4441 49.8393L38.5788 52.8125C38.3023 52.9052 38.0389 52.6418 38.1316 52.3653L41.1047 43.5C41.527 42.2416 42.2459 41.08 43.1844 40.1417L69.8028 13.5231L77.4208 21.1411L50.8024 47.7597Z"
              fill="url(#paint2_linear_580_14494)"
            />
          </g>
          <g id="Group_5">
            <g id="Group_6">
              <path
                id="Vector_4"
                d="M52.7824 48.7574C51.2596 50.2801 49.4044 51.4291 47.3625 52.1139L34.8002 56.3268C34.0466 56.5796 33.3619 57.0036 32.7999 57.5656L17.5601 72.8055L19.1925 74.4379C19.5374 74.7828 20.0964 74.7828 20.4411 74.4379L34.5657 60.3134C35.1276 59.7514 35.8123 59.3274 36.5659 59.0747L49.1284 54.8617C51.1594 54.1806 53.0336 53.0199 54.5481 51.5053L84.9124 21.1409L82.6555 18.884L52.7824 48.7574Z"
                fill="url(#paint3_linear_580_14494)"
              />
            </g>
          </g>
          <g id="Group_7">
            <path
              id="Vector_5"
              d="M89.9966 21.4686C89.9106 19.5444 88.2813 18.0544 86.357 18.14C85.1853 18.1923 84.1186 17.457 83.7646 16.3387C83.183 14.5016 81.9593 12.5123 80.1954 10.7484C78.4314 8.98439 76.4421 7.7606 74.605 7.17912C73.4869 6.82509 72.7516 5.75828 72.8038 4.58669C72.8896 2.66277 71.3993 1.03275 69.4752 0.947143C67.5509 0.861186 65.9214 2.35146 65.8355 4.27556L65.6407 8.63353C65.5969 9.6114 65.9664 10.5627 66.6585 11.255L79.6884 24.2849C80.3808 24.9773 81.3322 25.3466 82.3104 25.303L86.668 25.1083C88.5923 25.0223 90.0826 23.3928 89.9966 21.4686Z"
              fill="#1943E8"
            />
          </g>
          <g id="Group_8">
            <path
              id="Vector_6"
              d="M5.89344 88.9115L2.03223 85.0503L7.95043 79.1321C8.36229 78.7203 9.03008 78.7203 9.44193 79.1321L11.8116 81.5018C12.2235 81.9137 12.2235 82.5815 11.8116 82.9933L5.89344 88.9115Z"
              fill="#476BFA"
            />
          </g>
          <g id="Group_9">
            <path
              id="Vector_7"
              d="M4.39009 90.6865L0.257476 86.5539C-0.0858252 86.2106 -0.0858252 85.6542 0.257476 85.3109L3.02181 82.5468C3.36511 82.2035 3.92146 82.2035 4.26476 82.5468L8.39738 86.6794C8.74068 87.0227 8.74068 87.579 8.39738 87.9223L5.63304 90.6867C5.28974 91.0296 4.73322 91.0296 4.39009 90.6865Z"
              fill="#1943E8"
            />
          </g>
          <g id="Group_10">
            <path
              id="Vector_8"
              d="M63.8004 36.191C62.5935 37.3979 60.6367 37.3979 59.43 36.191L54.7526 31.5137C53.5457 30.3068 53.5457 28.35 54.7526 27.1432C55.9595 25.9363 57.9163 25.9363 59.123 27.1432L63.8004 31.8206C65.0073 33.0273 65.0073 34.9841 63.8004 36.191Z"
              fill="#BED8FB"
            />
          </g>
          <g id="Group_11">
            <path
              id="Vector_9"
              d="M83.1704 58.4085C83.1632 56.1089 83.3889 53.8144 83.8441 51.5604L86.089 40.4443C86.5308 38.2571 84.8586 36.2136 82.6274 36.2136H58.5014C56.4565 36.2136 54.8392 37.9449 54.9779 39.985L55.9116 53.7137L60.0238 68.1199L51.6043 68.9592L42.0103 72.5873L34.9158 84.0479V88.5943H47.2048C48.7524 88.5943 50.2993 88.5247 51.8407 88.3857L61.5802 87.5068C63.5331 87.3305 65.4987 87.3487 67.4481 87.5613L75.9006 88.4825C77.7011 88.6787 79.5338 88.4233 81.1781 87.6636C84.6598 86.0552 87.0759 82.5322 87.0759 78.4449C87.0759 77.0641 86.7999 75.7482 86.3 74.5487C84.1732 69.4447 83.1876 63.9375 83.1704 58.4085Z"
              fill="url(#paint4_linear_580_14494)"
            />
          </g>
          <g id="Group_12">
            <path
              id="Vector_10"
              d="M56.0761 56.3896C56.1295 57.5229 56.158 58.6574 56.158 59.7922V63.4351C56.158 65.7231 54.742 67.7722 52.6021 68.5815L51.604 68.9589L57.2647 87.8956L61.5799 87.5063C63.5329 87.33 65.4985 87.3482 67.4479 87.5608L75.9003 88.482C76.9534 88.5968 78.0172 88.5557 79.0482 88.3509L56.0761 56.3896Z"
              fill="#EFECEF"
            />
          </g>
          <g id="Group_13">
            <path
              id="Vector_11"
              d="M36.4957 83.364H26.6215C25.1773 83.364 24.0066 84.5347 24.0066 85.9789C24.0066 87.4232 25.1773 88.5939 26.6215 88.5939H36.4957V83.364Z"
              fill="url(#paint5_linear_580_14494)"
            />
          </g>
          <g id="Group_14">
            <path
              id="Vector_12"
              d="M24.6019 84.3184C24.2301 84.7699 24.0068 85.3484 24.0068 85.979C24.0068 87.4232 25.1775 88.5939 26.6218 88.5939H36.4959V85.2727H26.6218C25.8081 85.2727 25.0814 84.9009 24.6019 84.3184Z"
              fill="url(#paint6_linear_580_14494)"
            />
          </g>
          <g id="Group_15">
            <path
              id="Vector_13"
              d="M36.3933 75.5893C35.4175 75.2777 34.352 75.4461 33.5198 76.0434L33.1484 76.31C32.2857 76.9293 31.2484 77.258 30.1865 77.2489L29.368 77.2419C29.3649 77.2385 29.3622 77.2348 29.3591 77.2315L26.6656 76.3064C26.3005 76.1808 25.9013 76.1645 25.5362 76.2902C24.1113 76.7804 23.0867 78.1294 23.0867 79.7207C23.0867 81.7267 24.7128 83.3529 26.7188 83.3529H38.6895L36.3933 75.5893Z"
              fill="#476BFA"
            />
          </g>
          <g id="Group_16">
            <path
              id="Vector_14"
              d="M26.7188 80.4977C25.2201 80.4977 23.9341 79.5896 23.3792 78.2939C23.1914 78.7318 23.0867 79.2136 23.0867 79.7204C23.0867 81.7264 24.7128 83.3526 26.7188 83.3526H38.6895L37.8451 80.4975H26.7188V80.4977Z"
              fill="#476BFA"
            />
          </g>
          <g id="Group_17">
            <path
              id="Vector_15"
              d="M83.4973 53.6115L83.4372 53.5749L55.3076 44.8335L55.9116 53.7134L58.3478 58.7058L83.6177 64.6658C83.3235 62.5969 83.177 60.5042 83.1704 58.4082C83.1653 56.8028 83.2763 55.2 83.4973 53.6115Z"
              fill="#EFECEF"
            />
          </g>
          <g id="Group_18">
            <path
              id="Vector_16"
              d="M83.8439 51.5604L84.0677 50.4519L55.9114 53.7135L55.9914 54.8892C56.1024 56.5213 56.158 58.1566 56.158 59.7924V60.6741L84.598 69.512C83.6409 65.9003 83.1819 62.1595 83.1701 58.4083C83.1631 56.1089 83.3888 53.8144 83.8439 51.5604Z"
              fill="#EFECEF"
            />
          </g>
          <g id="Group_19">
            <path
              id="Vector_17"
              d="M45.2325 71.3684L42.0102 72.5869L41.0647 73.6476L49.8984 88.5206C50.5465 88.4867 51.1941 88.4434 51.8406 88.3851L61.5801 87.5062C62.4446 87.4281 63.3116 87.3905 64.1785 87.3888L45.2325 71.3684Z"
              fill="url(#paint7_linear_580_14494)"
            />
          </g>
          <g id="Group_20">
            <path
              id="Vector_18"
              d="M42.0015 72.5903L37.2422 74.3902C35.8421 74.9196 34.9158 76.2603 34.9158 77.7571V88.594H47.2048C48.7524 88.594 50.2993 88.5244 51.8407 88.3852L51.9008 88.3797L42.0015 72.5903Z"
              fill="#EFECEF"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_580_14494"
          x1="50.1547"
          y1="19.4846"
          x2="50.1547"
          y2="25.6054"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_580_14494"
          x1="14.4045"
          y1="70.5745"
          x2="14.4045"
          y2="82.5043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_580_14494"
          x1="50.5797"
          y1="6.03149"
          x2="50.5797"
          y2="74.6968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_580_14494"
          x1="51.2362"
          y1="18.884"
          x2="51.2362"
          y2="74.6966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_580_14494"
          x1="60.9958"
          y1="36.2136"
          x2="60.9958"
          y2="88.5943"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_580_14494"
          x1="30.2511"
          y1="83.364"
          x2="30.2511"
          y2="88.5939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_580_14494"
          x1="30.2514"
          y1="84.3184"
          x2="30.2514"
          y2="88.5939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_580_14494"
          x1="52.6216"
          y1="71.3684"
          x2="52.6216"
          y2="88.5206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_580_14494">
          <rect width="90" height="90" fill="white" transform="translate(0 0.943848)" />
        </clipPath>
      </defs>
    </svg>
  );
};
