export const HelpsWithIcon5 = () => {
  return (
    <svg width="98" height="97" viewBox="0 0 98 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="star " clipPath="url(#clip0_580_14691)" filter="url(#filter0_d_580_14691)">
        <g id="Layer_2_00000096021273016167918260000012612172293593108647_">
          <g id="Layer_1-2">
            <g id="_45.Star">
              <path
                id="Vector"
                d="M64.9785 27.3278L49 44.1325V2.33172C51.2324 2.31415 53.2715 3.57977 54.2559 5.58368L63.625 24.5681C63.6426 24.6032 63.6602 24.6384 63.6777 24.6911L64.9785 27.3278Z"
                fill="url(#paint0_linear_580_14691)"
              />
              <path
                id="Vector_2"
                d="M33.0215 27.3278L49 44.1325V2.33172C46.7676 2.31415 44.7285 3.57977 43.7441 5.58368L34.375 24.5681C34.3574 24.6032 34.3398 24.6384 34.3223 24.6911L33.0215 27.3278Z"
                fill="url(#paint1_linear_580_14691)"
              />
              <path
                id="Vector_3"
                d="M93.4551 34.1482L49 44.1326L64.9785 27.3279L67.8789 27.7498C67.9141 27.7498 67.9844 27.7673 68.0195 27.7673L88.9902 30.8259C90.9414 31.1072 92.6289 32.3552 93.4551 34.1482Z"
                fill="#476BFA"
              />
              <path
                id="Vector_4"
                d="M92.2246 40.8103L77.0723 55.5935L74.875 57.738L49 44.1326L93.4551 34.1482C94.5098 36.3982 94.0176 39.0701 92.2246 40.8103Z"
                fill="url(#paint2_linear_580_14691)"
              />
              <path
                id="Vector_5"
                d="M76.9316 87.1287L49 44.1326L74.8574 57.738L75.3848 60.7615L78.9531 81.6443C79.3223 83.7009 78.5488 85.8103 76.9316 87.1287Z"
                fill="url(#paint3_linear_580_14691)"
              />
              <path
                id="Vector_6"
                d="M76.9316 87.1287C75.1211 88.658 72.5547 88.9216 70.4805 87.8142L51.7422 77.9529C51.707 77.9353 51.6543 77.9177 51.6016 77.9001L49 76.5291V44.1326L76.9316 87.1287Z"
                fill="url(#paint4_linear_580_14691)"
              />
              <path
                id="Vector_7"
                d="M49 44.1326V76.5291L46.3984 77.9001C46.3633 77.9177 46.293 77.9353 46.2578 77.9529L27.5195 87.8142C25.4277 88.9392 22.8789 88.658 21.0684 87.1287L49 44.1326Z"
                fill="url(#paint5_linear_580_14691)"
              />
              <path
                id="Vector_8"
                d="M49 44.1326L21.0859 87.1287C19.4688 85.8103 18.6777 83.7009 19.0469 81.6443L22.6152 60.7615L23.1426 57.738L49 44.1326Z"
                fill="#476BFA"
              />
              <path
                id="Vector_9"
                d="M49.0001 44.1326L23.1426 57.738L20.9454 55.5935L5.79303 40.8103C4.00006 39.0876 3.50787 36.4158 4.56256 34.1482L49.0001 44.1326Z"
                fill="url(#paint6_linear_580_14691)"
              />
              <path
                id="Vector_10"
                d="M49 44.1326L4.54492 34.1482C5.37109 32.3552 7.05859 31.1072 9.00977 30.8259L29.9629 27.7849C29.998 27.7849 30.0684 27.7673 30.1035 27.7673L33.0039 27.3455L49 44.1326Z"
                fill="#476BFA"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_580_14691"
          x="0"
          y="0.415771"
          width="98"
          height="98"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_580_14691" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_580_14691" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_580_14691"
          x1="56.9893"
          y1="2.33154"
          x2="56.9893"
          y2="44.1325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_580_14691"
          x1="41.0107"
          y1="2.33154"
          x2="41.0107"
          y2="44.1325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_580_14691"
          x1="71.5023"
          y1="34.1482"
          x2="71.5023"
          y2="57.738"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_580_14691"
          x1="64.0219"
          y1="44.1326"
          x2="64.0219"
          y2="87.1287"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_580_14691"
          x1="62.9658"
          y1="44.1326"
          x2="62.9658"
          y2="88.4922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_580_14691"
          x1="35.0342"
          y1="44.1326"
          x2="35.0342"
          y2="88.4997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_580_14691"
          x1="26.5066"
          y1="34.1482"
          x2="26.5066"
          y2="57.738"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#012EDE" />
          <stop offset="1" stopColor="#5477FF" />
        </linearGradient>
        <clipPath id="clip0_580_14691">
          <rect width="90" height="90" fill="white" transform="translate(4 0.415771)" />
        </clipPath>
      </defs>
    </svg>
  );
};
