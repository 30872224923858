import clsx from 'clsx';
import { useState } from 'react';
import SwitchSelector from 'react-switch-selector';

import stylesCommon from '../../Landing.module.scss';
import styles from './LandingPrice.module.scss';
import { LandingButton } from '../LandingButton';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import { LANDING_SECTION_CLASS } from '@/landing/util';

enum SELECTED_PERIOD {
  HALF_YEAR,
  MONTH,
}

enum PROF_PLAN {
  '250p' = '250',
  '350p' = '350',
  '500p' = '500',
  '1000p' = '1000',
}

interface Props {
  openPhoneModal: () => void;
  openBidModal: () => void;
}

export const LandingPrice = (props: Props) => {
  const { openBidModal, openPhoneModal } = props;
  const [selectedPeriod, setSelectedPeriod] = useState(SELECTED_PERIOD.HALF_YEAR);
  const [selectedProfPlan, setSelectedProfPlan] = useState(PROF_PLAN['250p']);

  const onChangePeriod = (value: any) => {
    setSelectedPeriod(value);
  };

  const onChangeProfPlan = (value: any) => {
    setSelectedProfPlan(value);
  };

  const periodOptions = [
    {
      label: 'Полгода',
      value: SELECTED_PERIOD.HALF_YEAR,
    },
    {
      label: 'Месяц',
      value: SELECTED_PERIOD.MONTH,
    },
  ];

  const optionsProfPlan = [
    {
      label: '250',
      value: PROF_PLAN['250p'],
    },
    {
      label: '350',
      value: PROF_PLAN['350p'],
    },
    {
      label: '500',
      value: PROF_PLAN['500p'],
    },
    {
      label: '1000',
      value: PROF_PLAN['1000p'],
    },
  ];

  const getMonthPrice = () => {
    switch (selectedProfPlan) {
      case PROF_PLAN['250p']:
        return {
          oldPrice: '7000',
          newPrice: '5600',
        };
      case PROF_PLAN['350p']:
        return {
          oldPrice: '9800',
          newPrice: '7840',
        };
      case PROF_PLAN['500p']:
        return {
          oldPrice: '14000',
          newPrice: '11200',
        };
      case PROF_PLAN['1000p']:
        return {
          oldPrice: '28000',
          newPrice: '22400',
        };
    }
  };

  const getHalfYearPrice = () => {
    switch (selectedProfPlan) {
      case PROF_PLAN['250p']:
        return {
          oldPrice: '5600',
          newPrice: '4480',
        };
      case PROF_PLAN['350p']:
        return {
          oldPrice: '7840',
          newPrice: '6270',
        };
      case PROF_PLAN['500p']:
        return {
          oldPrice: '11200',
          newPrice: '8690',
        };
      case PROF_PLAN['1000p']:
        return {
          oldPrice: '22400',
          newPrice: '17920',
        };
    }
  };

  const standardPlan = {
    oldPrice: selectedPeriod === SELECTED_PERIOD.MONTH ? '5000' : '5000',
    newPrice: selectedPeriod === SELECTED_PERIOD.MONTH ? '4000' : '4000',
  };

  const professionalPlan = {
    ...(selectedPeriod === SELECTED_PERIOD.MONTH ? getMonthPrice() : getHalfYearPrice()),
  };

  const priceCardText = `цена при оплате подписки${
    selectedPeriod === SELECTED_PERIOD.HALF_YEAR ? ' на полгода' : ' на месяц'
  }`;

  const planSwitcher = (
    <SwitchSelector
      name={'profPlan'}
      onChange={onChangeProfPlan}
      options={optionsProfPlan}
      initialSelectedIndex={0}
      backgroundColor={''}
      selectedBackgroundColor={'#3253ef'}
      fontColor={'#101828'}
      selectedFontColor={'#fff'}
      fontSize={16}
      wrapperBorderRadius={6}
      optionBorderRadius={6}
      selectionIndicatorMargin={2}
    />
  );

  return (
    <div className={clsx(styles.price, LANDING_SECTION_CLASS)} id="landing-price">
      <div className={stylesCommon.wrapper}>
        <h2 className={styles.priceHeader}>Тарифы</h2>
        <div className={clsx(styles.priceText)}>
          Ознакомьтесь тарифами на подписку и свяжитесь с нами для получения индивидуального предложения
        </div>

        <div className={styles.priceContent}>
          <div className={styles.periodSwitcher}>
            <SwitchSelector
              name={'period'}
              onChange={onChangePeriod}
              options={periodOptions}
              initialSelectedIndex={0}
              backgroundColor={'#172D5D'}
              selectedBackgroundColor={'#fff'}
              fontColor={'#fff'}
              selectedFontColor={'#011135'}
              fontSize={16}
              wrapperBorderRadius={12}
              optionBorderRadius={12}
              selectionIndicatorMargin={3}
              border={'none'}
            />
          </div>
          <div className={styles.priceCards}>
            <div className={styles.priceCard1}>
              <div>
                <h5 className={styles.priceCardHeader}>Стандарт</h5>
                <div className={styles.priceCardSubHeader}>Идеально для начинающих</div>
                <div className={styles.priceCardPriceWrapper}>
                  <div className={styles.priceCardPriceOld}>{standardPlan.oldPrice}₽</div>
                  <div className={styles.priceCardPrice}>
                    <div className={styles.priceCardPriceLeft}>
                      {standardPlan.newPrice}
                      <span>₽</span>
                    </div>
                    <div className={styles.priceCardPriceMonth}>/ месяц</div>
                  </div>
                </div>
                <p className={styles.priceCardText}>{priceCardText}</p>

                <div className={styles.priceTable}>
                  <div className={styles.priceTableItem}>
                    <div className={styles.priceTableKey}>Организации</div>
                    <div className={styles.priceTableValue}>1</div>
                  </div>
                  <div className={styles.priceTableItem}>
                    <div className={styles.priceTableKey}>Пользователи</div>
                    <div className={styles.priceTableValue}>1</div>
                  </div>
                  <div className={styles.priceTableItem}>
                    <div className={styles.priceTableKey}>Сотрудники</div>
                    <div className={styles.priceTableValue}>100</div>
                  </div>
                </div>
              </div>
              <LandingButton className={styles.priceCardButton} variant="fancy" sz="sm" onClick={openBidModal}>
                Начать
                <LandingChevronRightIcon />
              </LandingButton>
            </div>
            <div className={styles.priceCard2}>
              <h5 className={styles.priceCardHeader}>Профессионал</h5>
              <div className={styles.priceCardSubHeader}>для высоких запросов</div>
              <div className={styles.priceCardPriceWrapper}>
                <div className={styles.priceCardPriceOld}>{professionalPlan.oldPrice}₽</div>
                <div className={styles.priceCardPrice}>
                  <div className={styles.priceCardPriceLeft}>
                    {professionalPlan.newPrice}
                    <span>₽</span>
                  </div>
                  <div className={styles.priceCardPriceMonth}>/ месяц</div>
                </div>
              </div>
              <p className={styles.priceCardText}>{priceCardText}</p>

              <div className={styles.priceTable}>
                <div className={styles.priceTableItem}>
                  <div className={styles.priceTableKey}>Организации</div>
                  <div className={styles.priceTableValue}>∞</div>
                </div>
                <div className={styles.priceTableItem}>
                  <div className={styles.priceTableKey}>Пользователи</div>
                  <div className={styles.priceTableValue}>5</div>
                </div>
                <div className={styles.priceTableItem}>
                  <div className={styles.priceTableKey}>Кол-во сотрудников</div>
                  <div className={styles.priceTableValue}>{selectedProfPlan}</div>
                </div>
              </div>
              <div className={styles.profPlanSwitcher}>{planSwitcher}</div>

              <LandingButton variant="fancy" sz="sm" className={styles.priceCardButton} onClick={openBidModal}>
                Начать
                <LandingChevronRightIcon />
              </LandingButton>
            </div>
            <div className={styles.priceCard3}>
              <div>
                <h5 className={styles.priceCardHeader}>Бизнес</h5>
                <div className={clsx(styles.priceCardSubHeader, styles.priceCardSubHeaderCalc)}>
                  для высоких запросов
                </div>
              </div>
              <div>
                <p className={clsx(styles.priceCardTextIndividual)}>Подготовим коммерческое предложение</p>
                <LandingButton
                  sz="sm"
                  variant="secondary"
                  className={styles.priceCardButtonCalc}
                  onClick={openPhoneModal}
                >
                  Рассчитать
                  <LandingChevronRightIcon />
                </LandingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
