export const LandingFunctionalityIcon1 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.0144 4.96557C26.385 3.87675 25.2586 3.2251 23.9998 3.2251C22.7411 3.2251 21.6147 3.87675 20.9842 4.96679L20.9853 4.96566L0.472149 40.4975C-0.157195 41.5863 -0.157195 42.8873 0.471024 43.9773C1.10037 45.0673 2.22677 45.719 3.48555 45.719H44.5143C45.7731 45.719 46.8994 45.0673 47.5288 43.9773C48.1571 42.8873 48.1571 41.5863 47.5288 40.4975L27.0144 4.96557ZM23.9998 38.8742C22.6742 38.8742 21.5995 37.7994 21.5995 36.4738C21.5995 35.1482 22.6742 34.0734 23.9998 34.0734C25.3255 34.0734 26.4002 35.1482 26.4002 36.4738C26.4002 37.7994 25.3255 38.8742 23.9998 38.8742ZM26.4002 27.2908C26.4002 28.6163 25.3255 29.6912 23.9998 29.6912C22.6742 29.6912 21.5995 28.6164 21.5995 27.2908V15.5293C21.5995 14.2037 22.6742 13.1289 23.9998 13.1289C25.3255 13.1289 26.4002 14.2037 26.4002 15.5293V27.2908Z"
        fill="url(#paint0_linear_1484_1580)"
      />
      <path
        d="M26.4002 27.2908C26.4002 28.6163 25.3255 29.6912 23.9998 29.6912C22.6742 29.6912 21.5995 28.6164 21.5995 27.2908V15.5293C21.5995 14.2037 22.6742 13.1289 23.9998 13.1289C25.3255 13.1289 26.4002 14.2037 26.4002 15.5293V27.2908Z"
        fill="url(#paint1_linear_1484_1580)"
      />
      <path
        d="M23.9998 38.8742C22.6742 38.8742 21.5995 37.7994 21.5995 36.4738C21.5995 35.1482 22.6742 34.0734 23.9998 34.0734C25.3255 34.0734 26.4002 35.1482 26.4002 36.4738C26.4002 37.7994 25.3255 38.8742 23.9998 38.8742Z"
        fill="url(#paint2_linear_1484_1580)"
      />
      <path
        d="M27.0144 4.96557C26.385 3.87675 25.2586 3.2251 23.9998 3.2251C22.7411 3.2251 21.6147 3.87675 20.9842 4.96679L20.9853 4.96566L0.472149 40.4975C-0.157195 41.5863 -0.157195 42.8873 0.471024 43.9773C1.10037 45.0673 2.22677 45.719 3.48555 45.719H44.5143C45.7731 45.719 46.8994 45.0673 47.5288 43.9773C48.1571 42.8873 48.1571 41.5863 47.5288 40.4975L27.0144 4.96557ZM23.9998 38.8742C22.6742 38.8742 21.5995 37.7994 21.5995 36.4738C21.5995 35.1482 22.6742 34.0734 23.9998 34.0734C25.3255 34.0734 26.4002 35.1482 26.4002 36.4738C26.4002 37.7994 25.3255 38.8742 23.9998 38.8742ZM26.4002 27.2908C26.4002 28.6163 25.3255 29.6912 23.9998 29.6912C22.6742 29.6912 21.5995 28.6164 21.5995 27.2908V15.5293C21.5995 14.2037 22.6742 13.1289 23.9998 13.1289C25.3255 13.1289 26.4002 14.2037 26.4002 15.5293V27.2908Z"
        fill="url(#paint3_linear_1484_1580)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1484_1580"
          x1="24"
          y1="3.2251"
          x2="24"
          y2="45.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_1580"
          x1="24"
          y1="3.2251"
          x2="24"
          y2="45.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_1580"
          x1="24"
          y1="3.2251"
          x2="24"
          y2="45.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1484_1580"
          x1="24"
          y1="3.2251"
          x2="24"
          y2="45.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
