import { ModalCheckboxConnected } from '../ModalCheckbox';
import { ModalInput } from '../ModalInput';
import styles from '../LandingModal/LandingModal.module.scss';
import { LandingModal } from '../LandingModal';
import { LandingButton } from '../LandingButton';
import { FormProvider, useForm } from 'react-hook-form';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import { gql, useMutation } from '@apollo/client';
import { errorMessages } from '@/utils/form';
import { yandexMetrika } from '@/utils/metrika';

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreateDemoCallbackDocument = gql`
  mutation createDemoCallback($fio: String!, $phone: String!) {
    createDemoCallback(fio: $fio, phone: $phone)
  }
`;

export const LandingModalPhone = (props: Props) => {
  const [createDemoCallback] = useMutation(CreateDemoCallbackDocument);
  const { open, onClose } = props;
  const methods = useForm({
    defaultValues: {
      agree: true,
      name: '',
      phone: '',
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    await createDemoCallback({
      variables: {
        ...data,
      },
    }).then(() => {
      onClose();
    });
  };

  if (!open) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <LandingModal open={open} onClose={onClose}>
        <div className={styles.modalHeader}>Обратный звонок</div>
        <div className={styles.modalText}>Оставьте данные и мы свяжемся с вами в ближайшее время</div>
        <form className={styles.modalForm} onSubmit={handleSubmit(onSubmit)}>
          <ModalInput placeholder="Ваше имя" name="fio" rules={{ required: errorMessages.required }} />
          <ModalInput placeholder="Телефон" name="phone" rules={{ required: errorMessages.required }} />
          <LandingButton
            variant="fancy"
            sz="sm"
            onClick={() => {
              yandexMetrika(97444920, 'reachGoal', 'form call');
            }}
          >
            Отправить
            <LandingChevronRightIcon />
          </LandingButton>

          <div className={styles.modalCheckboxWrap}>
            <ModalCheckboxConnected name="agree" />
            <div className={styles.modalCheckboxText}>
              Нажимая кнопку Вы предоставляете согласие на обработку персональных данных в соответствии с{' '}
              <a href="https://pmtds.ru/legal/pd.pdf" target="_blank" rel="noreferrer">
                Политикой обработки персональных данных
              </a>
            </div>
          </div>
        </form>
      </LandingModal>
    </FormProvider>
  );
};
