export const LandingFunctionalityIcon3 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.92947 21.1211C2.93488 19.3961 2.76558 17.675 2.42407 15.9842L0.740131 7.64573C0.408779 6.00502 1.66312 4.47217 3.33678 4.47217H21.434C22.9679 4.47217 24.1811 5.77084 24.0771 7.30118L23.3767 17.5994L20.292 28.4058L26.6077 29.0355L33.8043 31.757L39.126 40.3539V43.7643H29.9078C28.7469 43.7643 27.5866 43.7121 26.4303 43.6078L19.1246 42.9485C17.6597 42.8162 16.1852 42.8299 14.723 42.9893L8.38265 43.6804C7.03205 43.8276 5.65732 43.636 4.42394 43.0661C1.81226 41.8596 -0.000102997 39.2169 -0.000102997 36.1509C-0.000102997 35.1152 0.206913 34.1281 0.581913 33.2283C2.17724 29.3997 2.91655 25.2686 2.92947 21.1211Z"
        fill="url(#paint0_linear_1484_801)"
      />
      <path
        d="M23.2543 19.6069C23.2143 20.457 23.1929 21.308 23.1929 22.1593V24.8919C23.1929 26.6082 24.255 28.1453 25.8602 28.7523L26.6089 29.0354L22.3627 43.2404L19.1258 42.9483C17.6609 42.8161 16.1864 42.8298 14.7242 42.9892L8.38386 43.6803C7.59391 43.7664 6.79591 43.7355 6.02258 43.5819L23.2543 19.6069Z"
        fill="url(#paint1_linear_1484_801)"
      />
      <path
        d="M37.9418 39.8413H45.3486C46.4319 39.8413 47.3101 40.7195 47.3101 41.8028C47.3101 42.8862 46.4319 43.7643 45.3486 43.7643H37.9418V39.8413Z"
        fill="url(#paint2_linear_1484_801)"
      />
      <path
        d="M46.8642 40.5571C47.1431 40.8959 47.3105 41.3298 47.3105 41.8028C47.3105 42.8861 46.4324 43.7643 45.3491 43.7643H37.9423V41.273H45.3491C45.9594 41.273 46.5045 40.9941 46.8642 40.5571Z"
        fill="url(#paint3_linear_1484_801)"
      />
      <path
        d="M38.0175 34.0092C38.7494 33.7754 39.5488 33.9018 40.173 34.3498L40.4516 34.5498C41.0987 35.0144 41.8768 35.261 42.6734 35.2541L43.2873 35.2488C43.2896 35.2463 43.2916 35.2435 43.294 35.241L45.3144 34.5471C45.5883 34.4529 45.8877 34.4407 46.1616 34.5349C47.2304 34.9027 47.999 35.9146 47.999 37.1083C47.999 38.613 46.7792 39.8329 45.2745 39.8329H36.2951L38.0175 34.0092Z"
        fill="url(#paint4_linear_1484_801)"
      />
      <path
        d="M45.2745 37.6912C46.3987 37.6912 47.3633 37.01 47.7796 36.0381C47.9204 36.3665 47.999 36.728 47.999 37.1081C47.999 38.6129 46.7792 39.8327 45.2745 39.8327H36.2951L36.9285 37.6911H45.2745V37.6912Z"
        fill="url(#paint5_linear_1484_801)"
      />
      <path
        d="M2.68457 17.5231L2.72966 17.4956L23.8301 10.9385L23.377 17.5995L21.5496 21.3444L2.59425 25.8152C2.81497 24.2633 2.92481 22.6935 2.92982 21.1212C2.93364 19.917 2.85031 18.7147 2.68457 17.5231Z"
        fill="url(#paint6_linear_1484_801)"
      />
      <path
        d="M2.42581 15.9843L2.25796 15.1528L23.3784 17.5995L23.3184 18.4813C23.2351 19.7056 23.1934 20.9323 23.1934 22.1594V22.8208L1.86015 29.4503C2.5781 26.741 2.92238 23.935 2.93122 21.1211C2.93649 19.3963 2.76719 17.6751 2.42581 15.9843Z"
        fill="url(#paint7_linear_1484_801)"
      />
      <path
        d="M31.3874 30.8428L33.8044 31.7568L34.5137 32.5524L27.8874 43.7091C27.4012 43.6836 26.9155 43.6512 26.4305 43.6074L19.1247 42.9481C18.4763 42.8896 17.826 42.8614 17.1756 42.86L31.3874 30.8428Z"
        fill="url(#paint8_linear_1484_801)"
      />
      <path
        d="M33.8109 31.7593L37.3809 33.1094C38.4311 33.5065 39.126 34.5122 39.126 35.635V43.764H29.9078C28.7469 43.764 27.5866 43.7118 26.4303 43.6074L26.3852 43.6033L33.8109 31.7593Z"
        fill="url(#paint9_linear_1484_801)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1484_801"
          x1="19.5629"
          y1="4.47217"
          x2="19.5629"
          y2="43.7643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_801"
          x1="16.3157"
          y1="19.6069"
          x2="16.3157"
          y2="43.7275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_801"
          x1="42.6259"
          y1="39.8413"
          x2="42.6259"
          y2="43.7643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1484_801"
          x1="42.6264"
          y1="40.5571"
          x2="42.6264"
          y2="43.7643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1484_801"
          x1="42.147"
          y1="33.894"
          x2="42.147"
          y2="39.8329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1484_801"
          x1="42.147"
          y1="36.0381"
          x2="42.147"
          y2="39.8327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1484_801"
          x1="13.2122"
          y1="10.9385"
          x2="13.2122"
          y2="25.8152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1484_801"
          x1="12.6193"
          y1="15.1528"
          x2="12.6193"
          y2="29.4503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1484_801"
          x1="25.8447"
          y1="30.8428"
          x2="25.8447"
          y2="43.7091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1484_801"
          x1="32.7556"
          y1="31.7593"
          x2="32.7556"
          y2="43.764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
