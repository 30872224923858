
import styles from './ThreeDotsSpinner.module.scss';

export const ThreeDotsSpinner = () => {
  return (
    <span className={styles.container}>
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
    </span>
  );
};
