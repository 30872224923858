export const LandingBurgerIcon = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.95 14.0001H10.05C9.45 14.0001 9 13.5501 9 12.8001C9 12.0501 9.45 11.6001 10.2 11.6001H37.95C38.55 11.6001 39.15 12.0501 39.15 12.8001C39.15 13.5501 38.55 14.0001 37.95 14.0001Z"
        fill="currentColor"
      />
      <path
        d="M37.95 24.5001H10.05C9.45 24.5001 9 24.0501 9 23.3001C9 22.5501 9.45 22.1001 10.2 22.1001H37.95C38.55 22.1001 39.15 22.5501 39.15 23.3001C39.15 24.0501 38.55 24.5001 37.95 24.5001Z"
        fill="currentColor"
      />
      <path
        d="M37.95 35.0001H10.05C9.45 35.0001 9 34.5501 9 33.8001C9 33.0501 9.45 32.6001 10.2 32.6001H37.95C38.55 32.6001 39.15 33.0501 39.15 33.8001C39.15 34.5501 38.55 35.0001 37.95 35.0001Z"
        fill="currentColor"
      />
    </svg>
  );
};
