import { createContext, useContext, useMemo, useState } from 'react';
// import { type Step } from 'react-joyride';

export interface DemoState {
  run: boolean;
  stepIndex: number;
  demoToken?: string;
  demoTokenRequested: boolean;
}

const demoState = {
  run: false,
  stepIndex: 0,
  demoTokenRequested: false,
  demoToken: '',
};

export const DemoContext = createContext({
  state: demoState,
  setState: () => undefined,
});
DemoContext.displayName = 'demoContext';

export function DemoProvider(props: any) {
  const [state, setState] = useState(demoState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  return <DemoContext.Provider value={value} {...props} />;
}

export function useDemoContext(): {
  setState: (patch: Partial<DemoState> | ((previousState: DemoState) => Partial<DemoState>)) => void;
  state: DemoState;
} {
  const context = useContext(DemoContext);

  if (!context) {
    throw new Error('useDemoContext must be used within a DemoProvider');
  }

  return context;
}
