export const LandingHelpIcon5 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.32149 21.6914H17.6786C18.618 21.6914 19.3822 20.9272 19.3822 19.9878V7.98715H15.4072C14.4678 7.98715 13.7036 7.22297 13.7036 6.28358V2.30859H6.32149C5.3821 2.30859 4.61792 3.07278 4.61792 4.01216V19.9878C4.61792 20.9272 5.3821 21.6914 6.32149 21.6914ZM8.59291 10.2964H15.4072C15.7211 10.2964 15.975 10.5504 15.975 10.8643C15.975 11.1782 15.7211 11.4321 15.4072 11.4321H8.59291C8.27904 11.4321 8.02505 11.1782 8.02505 10.8643C8.02505 10.5504 8.27904 10.2964 8.59291 10.2964ZM8.59291 12.5679H15.4072C15.7211 12.5679 15.975 12.8218 15.975 13.1357C15.975 13.4496 15.7211 13.7036 15.4072 13.7036H8.59291C8.27904 13.7036 8.02505 13.4496 8.02505 13.1357C8.02505 12.8218 8.27904 12.5679 8.59291 12.5679ZM8.59291 14.8393H15.4072C15.7211 14.8393 15.975 15.0933 15.975 15.4071C15.975 15.721 15.7211 15.975 15.4072 15.975H8.59291C8.27904 15.975 8.02505 15.721 8.02505 15.4071C8.02505 15.0933 8.27904 14.8393 8.59291 14.8393ZM8.59291 17.1107H13.1358C13.4496 17.1107 13.7036 17.3647 13.7036 17.6786C13.7036 17.9924 13.4496 18.2464 13.1358 18.2464H8.59291C8.27904 18.2464 8.02505 17.9924 8.02505 17.6786C8.02505 17.3647 8.27904 17.1107 8.59291 17.1107Z"
        fill="white"
      />
      <path
        d="M15.4072 6.85142H19.0495L14.8394 2.6413V6.28356C14.8394 6.59687 15.0939 6.85142 15.4072 6.85142Z"
        fill="white"
      />
    </svg>
  );
};
