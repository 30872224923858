export const LandingFunctionalityIcon7 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1484_1557)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 0.359863C37.2548 0.359863 48 11.105 48 24.3599C48 37.6147 37.2548 48.3599 24 48.3599C10.7452 48.3599 0 37.6147 0 24.3599C0 11.105 10.7452 0.359863 24 0.359863ZM29.5518 9.59433L18.4484 9.59424L18.4482 18.8082L9.23428 18.8083L9.23438 29.9115H18.4483L18.4484 39.1254L29.552 39.1255V29.9115L38.7659 29.9115L38.7658 18.8082H29.5519L29.5518 9.59433Z"
          fill="url(#paint0_linear_1484_1557)"
        />
        <path
          d="M29.5518 9.59433L18.4484 9.59424L18.4482 18.8082L9.23428 18.8083L9.23438 29.9115H18.4483L18.4484 39.1254L29.552 39.1255V29.9115L38.7659 29.9115L38.7658 18.8082H29.5519L29.5518 9.59433Z"
          fill="url(#paint1_linear_1484_1557)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 0.359863C37.2548 0.359863 48 11.105 48 24.3599C48 37.6147 37.2548 48.3599 24 48.3599C10.7452 48.3599 0 37.6147 0 24.3599C0 11.105 10.7452 0.359863 24 0.359863ZM29.5518 9.59433L29.5517 9.59424H18.4484L18.4482 9.59443V18.8082L9.23428 18.8083V18.8085L9.23438 29.9115H9.23466H18.4483L18.4484 39.1254L18.4485 39.1255H29.552V29.9115L38.7659 29.9115L38.7658 18.8082H29.5519L29.5518 9.59433Z"
          fill="url(#paint2_linear_1484_1557)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1484_1557"
          x1="24"
          y1="0.359863"
          x2="24"
          y2="48.3599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_1557"
          x1="24"
          y1="0.359863"
          x2="24"
          y2="48.3599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_1557"
          x1="24"
          y1="0.359863"
          x2="24"
          y2="48.3599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <clipPath id="clip0_1484_1557">
          <rect width="48" height="48" fill="white" transform="translate(0 0.359863)" />
        </clipPath>
      </defs>
    </svg>
  );
};
