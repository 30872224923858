import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

import { client } from '@/apollo/config';
import App from './App';
import { DemoProvider } from './components/Demo/context';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <DemoProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DemoProvider>
    <ToastContainer autoClose={false} />
  </ApolloProvider>,
  // </React.StrictMode>,
);
