declare global {
  interface Window {
    ym: any;
  }
}

export const yandexMetrika = (id: number, type: string, text: string) => {
  if (window.ym) {
    window.ym(id, type, text);
  }
  // console.log('yandexMetrika', id, type, text);
};
