import useGlobalErrorHandler from '@/hooks/useGlobalErrorHandler';

import moment from 'moment';
import 'moment/dist/locale/ru';
import { Suspense, lazy } from 'react';
import { AppPreloader } from './components/app/AppPreloader';
import Landing from './landing/Landing';
import { ErrorBoundary } from 'react-error-boundary';

import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

const RenderRoutesDefault = lazy(async () => await import('./routes'));

function App() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const bypassUnderConstruction = params.get('bypassUnderConstruction') === 'true';
  if (bypassUnderConstruction) {
    localStorage.setItem('bypassUnderConstruction', 'true');
  }
  moment.locale('ru');
  useGlobalErrorHandler();

  const path = location.pathname;
  // throw path;
  if (path === '/' || path === '') {
    return <Landing />;
  }
  return (
    <Suspense fallback={<AppPreloader />}>
      <ErrorBoundary
        fallbackRender={(data) => {
          const { error } = data;
          const typedError = error as Error;
          if (typedError.message.includes('to fetch')) {
            window.location.reload();
          }
          return null;
        }}
      >
        <RenderRoutesDefault />
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
