export const LandingHelpIcon3 = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.2983 11.4357H12.8548V1.99221C17.9352 2.27187 22.0187 6.35543 22.2983 11.4357ZM11.7419 1.99221C6.47737 2.28194 2.28271 6.65651 2.28271 11.9922C2.28271 17.5146 6.77592 22.0078 12.2984 22.0078C14.8598 22.0078 17.1995 21.0407 18.9724 19.4531L11.7419 12.2226L11.7419 1.99221ZM19.7593 18.6662C21.2311 17.0225 22.1693 14.8917 22.2983 12.5486H13.6416L19.7593 18.6662Z"
        fill="white"
      />
    </svg>
  );
};
