export const LandingCloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <rect y="0.344238" width="24" height="24" rx="6" fill="#F2F4F7" />
      <path
        d="M16.5187 17.0741L6.65454 7.20999C6.44241 6.99786 6.44241 6.67966 6.70757 6.4145C6.97274 6.14933 7.29094 6.14933 7.5561 6.4145L17.3672 16.2256C17.5793 16.4377 17.6324 16.809 17.3672 17.0741C17.102 17.3393 16.7308 17.2863 16.5187 17.0741Z"
        fill="#98A2B3"
      />
      <path
        d="M17.3671 7.26306L7.50296 17.1272C7.29083 17.3393 6.97263 17.3393 6.70747 17.0742C6.4423 16.809 6.4423 16.4908 6.70747 16.2256L16.5186 6.41453C16.7307 6.2024 17.1019 6.14937 17.3671 6.41453C17.6323 6.6797 17.5792 7.05093 17.3671 7.26306Z"
        fill="#98A2B3"
      />
    </svg>
  );
};
