import { useDemoContext } from '@/components/Demo/context';
import { LoginDemoDocument } from '@/gql/generated';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

export const TOKEN_KEY = 'token';

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const deleteToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const useDemoToken = () => {
  const [run, { loading }] = useMutation(LoginDemoDocument);
  const demoContext = useDemoContext();

  useEffect(() => {
    if (!demoContext.state.demoTokenRequested && !demoContext.state.demoToken) {
      demoContext.setState({ ...demoContext.state, demoTokenRequested: true });
      run().then((data) => {
        demoContext.setState({
          ...demoContext.state,
          demoToken: data.data?.loginDemo.token,
        });
      });
    }
  }, []);

  return {
    loading,
    demoToken: demoContext.state.demoToken,
  };
};
