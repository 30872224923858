import clsx from 'clsx';

import stylesCommon from '../../Landing.module.scss';
import styles from './LandingService.module.scss';
import { useEffect, useRef, useState } from 'react';
import { LandingServiceModal, LandingServiceModalCardWrapper } from './LandingServiceModal';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import { LANDING_SECTION_CLASS } from '@/landing/util';

interface Props {
  openBidModal: () => void;
  setIsServiceModalOpen: (value: boolean) => void;
}

export const LandingService = (props: Props) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const { openBidModal, setIsServiceModalOpen } = props;
  const [openModalIndex, setOpenModalIndex] = useState(0);

  // TODO custom hook
  useEffect(() => {
    if (openModalIndex !== 0) {
      setIsServiceModalOpen(true);
    } else {
      setIsServiceModalOpen(false);
    }

    return () => {
      setIsServiceModalOpen(false);
    };
  }, [openModalIndex]);

  const onModalOpen = () => {
    setOpenModalIndex(0);
    openBidModal();
  };

  return (
    <div ref={wrapper} id="landing-service" className={clsx(styles.serviceOuterWrapper, LANDING_SECTION_CLASS)}>
      <div>
        <div className={clsx(stylesCommon.wrapper)}>
          <h3 className={styles.serviceHeader}>Для кого</h3>
        </div>
      </div>
      <div className={clsx(stylesCommon.wrapper, styles.service)}>
        <div className={styles.twoColumns}>
          <div className={styles.serviceCardWrapperLeft}>
            <div className={styles.serviceCard}>
              <div className={styles.serviceCardLeft}>
                <h4 className={styles.serviceCardHeader}>Для специалистов по охране труда</h4>
                <div className={styles.serviceCardText}>
                  Автоматизируйте рутинные процессы. Формируйте необходимые документы, ставьте задачи и контролируйте
                  сроки проведения намеченных мероприятий.
                </div>
                <div
                  className={styles.serviceCardLink}
                  onClick={() => {
                    setOpenModalIndex(1);
                  }}
                >
                  Подробнее
                  <LandingChevronRightIcon />
                </div>
              </div>
              <div className={styles.serviceCardRight}>
                <video src="/landing-service-icon-1.mp4" autoPlay muted loop playsInline />
              </div>
            </div>

            <div className={styles.serviceCard}>
              <div className={styles.serviceCardLeft}>
                <h4 className={styles.serviceCardHeader}>Для аутсорсинговых компаний</h4>
                <div className={clsx(styles.serviceCardText, styles.serviceCardTextModal)}>
                  Осуществляйте совместную работу над проектами. Работайте одновременно с неограниченным количеством
                  клиентов.
                </div>
                <div
                  className={styles.serviceCardLink}
                  onClick={() => {
                    setOpenModalIndex(2);
                  }}
                >
                  Подробнее
                  <LandingChevronRightIcon />
                </div>
              </div>

              <div className={styles.serviceCardRight}>
                <video src="/landing-service-icon-3.mp4" autoPlay muted loop playsInline />
              </div>
            </div>
          </div>

          <div className={styles.serviceCardWrapperRight}>
            <div className={styles.serviceCard}>
              <div className={styles.serviceCardLeft}>
                <h4 className={styles.serviceCardHeader}>Для директора/собственника бизнеса</h4>
                <div className={styles.serviceCardText}>
                  Оптимизация и контроль всех процессов по охране труда. Необходимые документы всегда под рукой.
                </div>
                <div
                  className={styles.serviceCardLink}
                  onClick={() => {
                    setOpenModalIndex(3);
                  }}
                >
                  Подробнее
                  <LandingChevronRightIcon />
                </div>
              </div>

              <div className={styles.serviceCardRight}>
                <video src="/landing-service-icon-2.mp4" autoPlay muted loop playsInline />
              </div>
            </div>

            <div className={styles.serviceCard}>
              <div className={styles.serviceCardLeft}>
                <h4 className={styles.serviceCardHeader}>Для специалистов по кадрам или бухгалтеров</h4>
                <div className={clsx(styles.serviceCardText)}>
                  Если функции по охране труда поручены вам в дополнение к основным обязанностям и вы не знаете, что
                  делать, наш Сервис станет ежедневником и помощником по охране труда
                </div>

                <div
                  className={styles.serviceCardLink}
                  onClick={() => {
                    setOpenModalIndex(4);
                  }}
                >
                  Подробнее
                  <LandingChevronRightIcon />
                </div>
              </div>

              <div className={styles.serviceCardRight}>
                <video src="/landing-service-icon-4.mp4" autoPlay muted loop playsInline />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalIndex !== 0 && (
        <LandingServiceModal
          onClose={() => {
            setOpenModalIndex(0);
          }}
        >
          <LandingServiceModalCardWrapper index={openModalIndex} onModalOpen={onModalOpen} />
        </LandingServiceModal>
      )}
    </div>
  );
};
