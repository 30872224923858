import styles from './LandingMenu.module.scss';
import { LandingButton } from '../LandingButton';
import { LandingPhoneIcon3 } from '../icons/LandingPhoneIcon3';
import { LandingCloseIcon } from '../icons/LandingCloseIcon';

interface Props {
  setMenuIsOpen: (value: boolean) => void;
  openPhoneModal: () => void;
  goToSection: (id: string, event: React.MouseEvent) => void;
}

export const LandingMenu = ({ setMenuIsOpen, openPhoneModal, goToSection }: Props) => {
  const onMenuClose = () => {
    setMenuIsOpen(false);
  };

  const onClick = () => {
    setTimeout(() => {
      setMenuIsOpen(false);
    }, 1);
  };

  return (
    <>
      <div className={styles.menuWrapper} onClick={onClick}>
        <div>
          <div className={styles.menuTop}>
            <span className={styles.menuHeader}>Навигация</span>
            <div className={styles.menuButton} onClick={onMenuClose}>
              <LandingCloseIcon />
            </div>
          </div>
          <ul className={styles.menuItems}>
            <li>
              <a
                href="#landing-service"
                onClick={(event) => {
                  goToSection('landing-service', event);
                }}
              >
                Для кого?
              </a>
            </li>
            <li>
              <a
                href="#landing-helps-with"
                onClick={(event) => {
                  goToSection('landing-helps-with', event);
                }}
              >
                Возможности
              </a>
            </li>
            <li>
              <a
                href="#landing-price"
                onClick={(event) => {
                  goToSection('landing-price', event);
                }}
              >
                Тарифы
              </a>
            </li>
            <li>
              <a
                href="#landing-faq"
                onClick={(event) => {
                  goToSection('landing-faq', event);
                }}
              >
                FAQ
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.menuContacts}>
          <span className={styles.menuHeader}>Связь с нами</span>
          <a href="tel:74993504459" className={styles.menuPhone}>
            + 7 499 350 44 59
          </a>
          <a href="mailto:support@pmtds.ru" className={styles.menuEmail}>
            support@pmtds.ru
          </a>
        </div>
        <div>
          <span className={styles.menuHeader}>Адрес</span>
          <p className={styles.menuAddress}>111250 г. Москва, ул. Лефортовский Вал, д. 24, пом. 1/п</p>
        </div>
        <LandingButton onClick={openPhoneModal} variant="fancy" sz="sm">
          Заказать звонок
          <LandingPhoneIcon3 />
        </LandingButton>
      </div>
      <div
        className={styles.menuOverlay}
        onClick={() => {
          setMenuIsOpen(false);
        }}
      ></div>
    </>
  );
};
