import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { createUploadLink } from 'apollo-upload-client';

import { getToken } from '@/utils/token';
import { getApiUrl } from './getApiUrl';

const { link, useApolloNetworkStatus: networkStatusHook } = createNetworkStatusNotifier();

export const useApolloNetworkStatus = networkStatusHook;

export const abortController = new AbortController();

const httpLink = createUploadLink({
  uri: getApiUrl(),
  fetchOptions: {
    signal: abortController.signal,
  },
});

const withToken = setContext((_, { headers = {} }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const resetToken = onError(() => {});

const authFlowLink = withToken.concat(resetToken);

export const inMemoryCacheConfig = {
  typePolicies: {
    AuthProfile: {
      keyFields: ['__typename'],
    },
  },
};

export const client = new ApolloClient({
  link: link.concat(authFlowLink.concat(httpLink)),
  cache: new InMemoryCache(inMemoryCacheConfig),
  connectToDevTools: true,
});
