import styles from './LandingDemo.module.scss';
import clsx from 'clsx';
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from 'gsap/all';
import img from '../../assets/images/landing-laptop-img.webp';
import { LandingButton } from '../LandingButton';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import { type Ref, forwardRef } from 'react';
import { LANDING_SECTION_CLASS } from '@/landing/util';

gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);

interface Props {
  openBidModal: () => void;
}

export const LandingDemo = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { openBidModal } = props;

  return (
    <div className={clsx(styles.demo, LANDING_SECTION_CLASS)} ref={ref}>
      <div className={clsx(styles.demoScrollWrap)}>
        <div className={clsx(styles.demoInner)}>
          <img src={img} alt="Иллюстрация" />
          <LandingButton variant="fancy" sz="lg" className={clsx(styles.demoButton)} onClick={openBidModal}>
            Попробовать бесплатно
            <LandingChevronRightIcon />
          </LandingButton>
        </div>
      </div>
    </div>
  );
});

LandingDemo.displayName = 'LandingDemo';
