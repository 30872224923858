export const LandingFunctionalityIcon8 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1484_736)">
        <path
          d="M39.5685 20.6142C39.6067 20.1293 39.626 19.6418 39.626 19.1602C39.626 9.054 31.404 0.832031 21.2978 0.832031C11.1917 0.832031 2.96973 9.054 2.96973 19.1602C2.96973 24.1593 5.02266 28.9477 8.62323 32.3977V48.832H31.16V40.3945H39.626V33.5196H45.0292L39.5685 20.6142ZM18.4854 26.2383C22.3883 26.2383 25.5635 23.0631 25.5635 19.1602C25.5635 16.8081 23.6499 14.8945 21.2978 14.8945C18.9458 14.8945 17.0322 16.8081 17.0322 19.1602C17.0322 19.9614 17.6841 20.6133 18.4854 20.6133C19.2866 20.6133 19.9385 19.9614 19.9385 19.1602H22.751C22.751 21.5122 20.8374 23.4258 18.4854 23.4258C16.1333 23.4258 14.2197 21.5122 14.2197 19.1602C14.2197 15.2572 17.3949 12.082 21.2978 12.082C25.2008 12.082 28.376 15.2572 28.376 19.1602C28.376 24.6139 23.9391 29.0508 18.4854 29.0508C13.0316 29.0508 8.59473 24.6139 8.59473 19.1602C8.59473 12.1556 14.2933 6.45703 21.2978 6.45703C28.3024 6.45703 34.001 12.1556 34.001 19.1602H31.1885C31.1885 13.7064 26.7516 9.26953 21.2978 9.26953C15.8441 9.26953 11.4072 13.7064 11.4072 19.1602C11.4072 23.0631 14.5824 26.2383 18.4854 26.2383Z"
          fill="url(#paint0_linear_1484_736)"
        />
        <path
          d="M18.4854 26.2383C22.3883 26.2383 25.5635 23.0631 25.5635 19.1602C25.5635 16.8081 23.6499 14.8945 21.2978 14.8945C18.9458 14.8945 17.0322 16.8081 17.0322 19.1602C17.0322 19.9614 17.6841 20.6133 18.4854 20.6133C19.2866 20.6133 19.9385 19.9614 19.9385 19.1602H22.751C22.751 21.5122 20.8374 23.4258 18.4854 23.4258C16.1333 23.4258 14.2197 21.5122 14.2197 19.1602C14.2197 15.2572 17.3949 12.082 21.2978 12.082C25.2008 12.082 28.376 15.2572 28.376 19.1602C28.376 24.6139 23.9391 29.0508 18.4854 29.0508C13.0316 29.0508 8.59473 24.6139 8.59473 19.1602C8.59473 12.1556 14.2933 6.45703 21.2978 6.45703C28.3024 6.45703 34.001 12.1556 34.001 19.1602H31.1885C31.1885 13.7064 26.7516 9.26953 21.2978 9.26953C15.8441 9.26953 11.4072 13.7064 11.4072 19.1602C11.4072 23.0631 14.5824 26.2383 18.4854 26.2383Z"
          fill="url(#paint1_linear_1484_736)"
        />
        <path
          d="M39.5685 20.6142C39.6067 20.1293 39.626 19.6418 39.626 19.1602C39.626 9.054 31.404 0.832031 21.2978 0.832031C11.1917 0.832031 2.96973 9.054 2.96973 19.1602C2.96973 24.1593 5.02266 28.9477 8.62323 32.3977V48.832H31.16V40.3945H39.626V33.5196H45.0292L39.5685 20.6142ZM18.4854 26.2383C22.3883 26.2383 25.5635 23.0631 25.5635 19.1602C25.5635 16.8081 23.6499 14.8945 21.2978 14.8945C18.9458 14.8945 17.0322 16.8081 17.0322 19.1602C17.0322 19.9614 17.6841 20.6133 18.4854 20.6133C19.2866 20.6133 19.9385 19.9614 19.9385 19.1602H22.751C22.751 21.5123 20.8374 23.4258 18.4854 23.4258C16.1333 23.4258 14.2197 21.5123 14.2197 19.1602C14.2197 15.2572 17.3949 12.082 21.2978 12.082C25.2008 12.082 28.376 15.2572 28.376 19.1602C28.376 24.6139 23.9391 29.0508 18.4854 29.0508C13.0316 29.0508 8.59473 24.6139 8.59473 19.1602C8.59473 12.1556 14.2933 6.45703 21.2978 6.45703C28.3024 6.45703 34.001 12.1556 34.001 19.1602H31.1885C31.1885 13.7064 26.7516 9.26953 21.2978 9.26953C15.8441 9.26953 11.4072 13.7064 11.4072 19.1602C11.4072 23.0631 14.5824 26.2383 18.4854 26.2383Z"
          fill="url(#paint2_linear_1484_736)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1484_736"
          x1="23.9994"
          y1="0.832031"
          x2="23.9994"
          y2="48.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_736"
          x1="23.9994"
          y1="0.832031"
          x2="23.9994"
          y2="48.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_736"
          x1="23.9994"
          y1="0.832031"
          x2="23.9994"
          y2="48.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1484_736">
          <rect width="48" height="48" fill="white" transform="translate(0 0.832031)" />
        </clipPath>
      </defs>
    </svg>
  );
};
