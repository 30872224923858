import clsx from 'clsx';
import styles from './LandingFunctionality.module.scss';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';

interface Props {
  icon: JSX.Element;
  title: string;
  description: string;
  expanded: boolean;
  onExpendBtn: () => void;
  removeBorder: boolean;
  isCollapsedItem?: boolean;
}

export const FunctionalityItem = ({
  icon,
  title,
  description,
  expanded,
  isCollapsedItem = true,
  removeBorder,
  onExpendBtn,
}: Props) => {
  return (
    <div
      className={clsx(
        styles.functionalityItemWrapper,
        expanded && styles.itemWrapperExpended,
        removeBorder && styles.itemWrapperNoBorder,
      )}
      onClick={onExpendBtn}
    >
      <div className={styles.functionalityItem}>
        <div className={styles.itemTitleContainer}>
          <div className={styles.functionalityItemIcon}>{icon}</div>
          <div className={styles.functionalityItemTitle}>{title}</div>
        </div>
        <div className={styles.itemDescContainer}>
          <div
            className={clsx(
              styles.functionalityItemDesc,
              !expanded && isCollapsedItem && styles.itemCollapsed,
              (expanded || !isCollapsedItem) && styles.itemExpended,
            )}
          >
            {description}
          </div>
          {!expanded && isCollapsedItem && (
            <div className={styles.functionalityItemBtn}>
              <button>
                Подробнее
                <LandingChevronRightIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
