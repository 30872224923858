import clsx from 'clsx';
import style from './LandingButton.module.scss';

type LandingButtonType = 'primary' | 'secondary' | 'primary-ghost' | 'link' | 'fancy';
type LandingButtonSize = 'md' | 'lg' | 'sm';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  variant?: LandingButtonType;
  type?: 'button' | 'submit' | 'reset' | undefined;
  sz?: LandingButtonSize;
}

const variantClassNameMap: Record<LandingButtonType, string> = {
  primary: style.primaryButton,
  secondary: style.secondaryButton,
  'primary-ghost': style.primaryGhostButton,
  link: style.linkButton,
  fancy: style.fancy,
};

const sizeClassNameMap: Record<LandingButtonSize, string> = {
  md: style.buttonSizeMd,
  lg: style.buttonSizeLg,
  sm: style.buttonSizeSm,
};

export const LandingButton = (props: Props) => {
  const { children, variant = 'primary', className, sz = 'md', ...rest } = props;
  const variantClassName = variantClassNameMap[variant];
  const sizeClassName = sizeClassNameMap[sz];

  return (
    <button className={clsx(variantClassName, sizeClassName, className)} {...rest}>
      {variant === 'fancy' && <div className={style.buttonBg} />}
      <div className={style.buttonInner}>{children}</div>
    </button>
  );
};

interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {}

export const LandingButtonLink = (props: LinkProps) => {
  const { children, className, ...rest } = props;
  const variantClassName = variantClassNameMap.link;
  return (
    <a className={clsx(variantClassName, className)} {...rest}>
      {children}
    </a>
  );
};
