import { ADMIN_PATH_CHUNK, CABINET_PATH_CHUNK, PROJECT_PATH_CHUNK } from '@/constants/routing';
import { type AuthProfile } from '@/gql/generated';

export const getAuthRedirectLink = (profile: AuthProfile) => {
  if (profile.is_admin_account) {
    return `/${ADMIN_PATH_CHUNK}`;
  } else if (profile.is_organization_account) {
    return `/${CABINET_PATH_CHUNK}/${PROJECT_PATH_CHUNK}`;
  }
  return '/';
};
