import { type ClassNamesConfig } from 'react-select';

import { Select } from '@/components/Form/';
import { selectAllByValue, selectByValue } from '@/utils/form';
import { type OperationVariables, useQuery } from '@apollo/client';
import { getQueryData } from '@/utils/apollo';
import { type DocumentNode } from 'graphql';
import { type AsyncSelectComponents } from '../AsyncSelect/AsyncSelectProps';

interface Props {
  query: DocumentNode;
  value: any;
  onChange: any;
  transformItem?: any;
  isMulti?: boolean;
  onInputChange?: (inputValue: string, meta: any) => void;
  queryVars?: OperationVariables;
  classNames?: ClassNamesConfig;
  placeholder?: string;
  noOptionsMessage?: ((obj: { inputValue: string }) => React.ReactNode) | undefined;
  components?: AsyncSelectComponents;
  onBlur?: () => void;
  inputValue?: string;
}

export const QuerySelect = (props: Props) => {
  const {
    value,
    onChange,
    query,
    transformItem = (item: any) => ({
      label: item.name,
      value: item.id,
    }),
    isMulti = true,
    onInputChange = () => {},
    queryVars = {},
    classNames = {},
    placeholder = '',
    noOptionsMessage,
    components = {},
    onBlur,
    inputValue,
  } = props;

  const response = useQuery(query, {
    variables: queryVars,
  });
  const items = getQueryData(response);
  const options = (items ?? []).map(transformItem);

  const getOriginalData = (selectedItems: any) => {
    if (isMulti) {
      return items.filter((item: any) => {
        const transformedItem = transformItem(item);
        return selectedItems.find((selectedItem: any) => selectedItem.value === transformedItem.value);
      });
    } else {
      return items.find((item: any) => {
        const transformedItem = transformItem(item);
        return transformedItem.value === selectedItems.value;
      });
    }
  };

  return (
    <Select
      onChange={(items) => {
        if (isMulti) {
          onChange(
            items.map((item: any) => item.value),
            getOriginalData(items),
          );
        } else {
          onChange(items.value, getOriginalData(items));
        }
      }}
      value={isMulti ? selectAllByValue(options, value) : selectByValue(options, value)}
      options={options}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      onInputChange={onInputChange}
      classNames={classNames}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      components={components}
      onBlur={onBlur}
      inputValue={inputValue}
    />
  );
};
