import { LandingLogo } from '../icons/LandingLogo';

import stylesCommon from '../../Landing.module.scss';
import styles from './LandingHeader.module.scss';
import clsx from 'clsx';
import { LandingMenu } from '../LandingMenu';
import { useCallback, useEffect, useState } from 'react';
import { LandingUserIcon } from '../icons/LandingUserIcon';
import { LandingBurgerIcon } from '../icons/LandingBurgerIcon';
import { LandingButton } from '../LandingButton';
import { LandingAuthMenu } from '../LandingAuthMenu';
import { gql, useQuery } from '@apollo/client';
import { useIsAuthorized } from '@/hooks/useIsAuthorized';
import { LandingProjectSelect } from '../LandingAsyncSelect/LandingProjectSelect';

const ProfileDocument = gql`
  query profile {
    profile {
      fio
      email
      phone
      is_admin_account
      is_organization_account
    }
  }
`;

interface Props {
  openPhoneModal: () => void;
  openAuthModal: () => void;
  goToSection: (id: string, event: React.MouseEvent) => void;
  logoRedirect?: boolean;
}

export const LandingHeader = (props: Props) => {
  const { openPhoneModal, openAuthModal, goToSection, logoRedirect } = props;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [authMenuIsOpen, setAuthMenuIsOpen] = useState(false);

  const onMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const onAuthMenuOpen = () => {
    setAuthMenuIsOpen(true);
  };

  const onScroll = useCallback(() => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }
    if (authMenuIsOpen) {
      setAuthMenuIsOpen(false);
    }
  }, [setAuthMenuIsOpen, setMenuIsOpen, menuIsOpen, authMenuIsOpen]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  const { loading } = useQuery(ProfileDocument);
  const isAuthorized = useIsAuthorized();

  return (
    <div className={clsx(styles.headerWrapper)} id="landing-header">
      <div className={clsx(stylesCommon.wrapper)}>
        <header className={clsx(styles.header)}>
          <div className={clsx(styles.headerLogo)}>
            {logoRedirect ? (
              <a href="/">
                <LandingLogo />
              </a>
            ) : (
              <LandingLogo />
            )}

            <div className={clsx(styles.headerSelectWrap)}>
              <LandingProjectSelect className={styles.headerProjectSelect} />
            </div>
          </div>

          <div className={clsx(styles.headerRight)}>
            <div className={styles.phone}>
              <a href="tel:74993504459">
                <div>+ 7 499 350 44 59</div>
              </a>
              <a href="mailto:support@pmtds.ru" className={styles.email}>
                <div>support@pmtds.ru</div>
              </a>
            </div>

            <div className={styles.buttonsWrapper}>
              {!loading && (
                <>
                  {isAuthorized ? (
                    <div>
                      <LandingButton variant="primary-ghost" className={styles.loginButton} onClick={onAuthMenuOpen}>
                        <LandingUserIcon />
                      </LandingButton>
                    </div>
                  ) : (
                    <div className={styles.loginButton} onClick={openAuthModal}>
                      <LandingUserIcon /> <span>Вход</span>
                    </div>
                  )}
                </>
              )}

              <div className={styles.menuButton} onClick={onMenuOpen}>
                <LandingBurgerIcon />
              </div>
            </div>
          </div>
          {menuIsOpen && (
            <LandingMenu setMenuIsOpen={setMenuIsOpen} openPhoneModal={openPhoneModal} goToSection={goToSection} />
          )}
          {authMenuIsOpen && <LandingAuthMenu setMenuIsOpen={setAuthMenuIsOpen} />}
        </header>
      </div>
    </div>
  );
};
