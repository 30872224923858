import { AsyncSelect } from '@/components/Form';
import { type AsyncSelectProps } from '@/components/Form/AsyncSelect/AsyncSelectProps';

import styles from './LandingAsyncSelect.module.scss';
import clsx from 'clsx';

export const LandingAsyncSelect = (props: AsyncSelectProps) => {
  return (
    <AsyncSelect
      {...props}
      classNames={{
        control: (data) => {
          const { isFocused } = data;
          return clsx(styles.container, { [styles.containerFocused]: isFocused });
        },
        menu: () => styles.menu,
        option: (data) => {
          const { isFocused } = data;
          return clsx(styles.option, { [styles.optionFocused]: isFocused });
        },
        placeholder: (data) => {
          const { isFocused } = data;
          return clsx(styles.placeholder, { [styles.placeholderFocused]: isFocused });
        },
        singleValue: (data) => {
          return styles.placeholder;
        },
      }}
      placeholder="Проекты"
      // defaultMenuIsOpen={true}
      // preloadItems={true}
    />
  );
};
