export const LandingPhoneIcon3 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
      <g clipPath="url(#clip0_1459_5025)">
        <path
          d="M16.5557 12.3149C15.4537 12.3149 14.3716 12.1426 13.3462 11.8037C12.8438 11.6323 12.2261 11.7896 11.9194 12.1045L9.8955 13.6324C7.54833 12.3794 6.10252 10.9341 4.8667 8.60455L6.34959 6.63336C6.73486 6.24861 6.87305 5.68658 6.70748 5.15923C6.36717 4.12845 6.1943 3.04691 6.1943 1.94441C6.19434 1.14795 5.54639 0.5 4.74998 0.5H1.44436C0.647953 0.5 0 1.14795 0 1.94436C0 11.0733 7.42678 18.5 16.5557 18.5C17.3521 18.5 18 17.852 18 17.0556V13.7593C18 12.9629 17.352 12.3149 16.5557 12.3149Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1459_5025">
          <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
