export const LandingHelpIcon4 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 19.2C13.5463 19.2 14.8 17.9464 14.8 16.4C14.8 14.8536 13.5463 13.6 12 13.6C10.4536 13.6 9.19995 14.8536 9.19995 16.4C9.19995 17.9464 10.4536 19.2 12 19.2Z"
        fill="white"
      />
      <path
        d="M5.75685 12.2L2.80005 11.608V21.192L5.75685 20.6C5.93783 20.5633 6.10062 20.4653 6.21778 20.3225C6.33495 20.1798 6.39932 20.001 6.40005 19.8164V12.9836C6.39932 12.7989 6.33495 12.6201 6.21778 12.4774C6.10062 12.3347 5.93783 12.2367 5.75685 12.2Z"
        fill="white"
      />
      <path
        d="M0.799927 12V20.8C0.799927 20.9061 0.842069 21.0078 0.917084 21.0828C0.992099 21.1578 1.09384 21.2 1.19993 21.2H1.99993V11.6H1.19993C1.09384 11.6 0.992099 11.6421 0.917084 11.7171C0.842069 11.7921 0.799927 11.8939 0.799927 12Z"
        fill="white"
      />
      <path
        d="M22.8 11.6H22V21.2H22.8C22.9061 21.2 23.0078 21.1578 23.0828 21.0828C23.1579 21.0078 23.2 20.9061 23.2 20.8V12C23.2 11.8939 23.1579 11.7921 23.0828 11.7171C23.0078 11.6421 22.9061 11.6 22.8 11.6Z"
        fill="white"
      />
      <path
        d="M17.6 12.9836V19.8164C17.6007 20.001 17.6651 20.1798 17.7822 20.3225C17.8994 20.4653 18.0622 20.5633 18.2432 20.6L21.2 21.192V11.608L18.2432 12.2C18.0622 12.2367 17.8994 12.3347 17.7822 12.4774C17.6651 12.6201 17.6007 12.7989 17.6 12.9836Z"
        fill="white"
      />
      <path
        d="M11.9999 6.79993C9.46991 6.79993 9.86231 9.91313 6.98071 12.1799C7.12393 12.4235 7.19959 12.7009 7.19991 12.9835V19.4163C9.48351 21.2507 14.4891 21.2719 16.7999 19.4163V12.9835C16.8002 12.7009 16.8759 12.4235 17.0191 12.1799C14.1867 9.95153 14.5131 6.79993 11.9999 6.79993ZM11.9999 19.9999C11.2879 19.9999 10.5919 19.7888 9.99986 19.3932C9.40784 18.9976 8.94642 18.4354 8.67395 17.7776C8.40147 17.1198 8.33018 16.3959 8.46909 15.6976C8.60799 14.9993 8.95086 14.3578 9.45433 13.8543C9.9578 13.3509 10.5993 13.008 11.2976 12.8691C11.9959 12.7302 12.7198 12.8015 13.3776 13.074C14.0354 13.3464 14.5976 13.8079 14.9932 14.3999C15.3888 14.9919 15.5999 15.6879 15.5999 16.3999C15.5989 17.3544 15.2192 18.2694 14.5443 18.9443C13.8694 19.6192 12.9544 19.9989 11.9999 19.9999Z"
        fill="white"
      />
      <path
        d="M20.34 9.08394C20.0749 9.33086 19.8865 9.64882 19.7971 9.99989C19.7078 10.351 19.7213 10.7203 19.836 11.0639L19.048 11.2239C18.9097 10.7489 18.9048 10.2449 19.0339 9.76718C19.163 9.28951 19.4212 8.85661 19.78 8.51594C20.504 7.79594 21.088 6.52794 20.644 5.45194C20.236 4.45994 18.568 2.79994 12 2.79994C5.43203 2.79994 3.76403 4.45994 3.35603 5.45194C2.91203 6.52794 3.49603 7.79594 4.22003 8.51594C4.5789 8.85661 4.83705 9.28951 4.96617 9.76718C5.09529 10.2449 5.0904 10.7489 4.95203 11.2239L4.16403 11.0639C4.27879 10.7203 4.29229 10.351 4.20293 9.99989C4.11356 9.64882 3.92511 9.33086 3.66003 9.08394C2.71603 8.14794 2.03203 6.57194 2.61603 5.14794C3.47603 3.05994 6.63603 1.99994 12 1.99994C17.364 1.99994 20.524 3.05994 21.384 5.14794C21.968 6.57194 21.284 8.14794 20.34 9.08394Z"
        fill="white"
      />
    </svg>
  );
};
