import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle } from '@/components/icons';

import styles from './InputWithErrorTooltip.module.scss';
import { type PropsWithChildren } from 'react';
import { Form, type FormControlProps } from '@/components/Form';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import clsx from 'clsx';

interface Props extends PropsWithChildren {
  tooltipText?: string;
  isInvalid?: boolean;
  iconClassName?: string;
  wrapperClassName?: string;
}

export const InputWithErrorTooltip = (props: Props) => {
  const { tooltipText, iconClassName, wrapperClassName } = props;
  const tooltip = (
    <Tooltip id="tooltip" style={{ position: 'fixed' }}>
      {tooltipText}
    </Tooltip>
  );

  return (
    <div className={clsx('position-relative', wrapperClassName)}>
      {props.children}
      {props.isInvalid && (
        <OverlayTrigger overlay={tooltip} placement="auto">
          <div className={`${styles.icon} ${iconClassName ?? ''}`} aria-label="error-icon" data-error={tooltipText}>
            <HelpCircle />
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};

InputWithErrorTooltip.displayName = 'InputWithErrorTooltip';

interface PropsConnected extends FormControlProps {
  name: string;
  rules?: any; // TODO type
  filterValue?: (value: string) => string;
  wrapperClassName?: string;
}

export const InputWithErrorConnected = (props: PropsConnected) => {
  const { name, rules, filterValue, wrapperClassName, ...restProps } = props;
  const methods = useFormContext();
  const {
    formState: { errors },
  } = methods;

  const error = (get(errors, name)?.message as string) ?? '';
  return (
    <InputWithErrorTooltip tooltipText={error} isInvalid={!!error} wrapperClassName={wrapperClassName}>
      <Controller
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Form.Control
            {...restProps}
            onChange={(event: any) => {
              const text = event.target.value;
              onChange(filterValue ? filterValue(text) : text);
            }}
            value={value ?? ''}
            {...restProps}
            isInvalid={!!error}
          />
        )}
      />
    </InputWithErrorTooltip>
  );
};
