import { type PropsWithChildren } from 'react';

import styles from './LandingService.module.scss';
import clsx from 'clsx';
import { LandingButton } from '../LandingButton';

import img1 from '../../assets/images/landing-service-icon-1.webp';
import img2 from '../../assets/images/landing-service-icon-2.webp';
import img3 from '../../assets/images/landing-service-icon-3.webp';
import img4 from '../../assets/images/landing-service-icon-4.webp';
import { LandingCloseIcon } from '../icons/LandingCloseIcon';

interface Props extends PropsWithChildren {
  onClose: () => void;
}

export const LandingServiceModal = (props: Props) => {
  const { onClose, children } = props;
  return (
    <>
      <div className={styles.serviceModal}>
        <div onClick={onClose} className={styles.serviceModalClose}>
          <LandingCloseIcon />
        </div>
        <div>{children}</div>
      </div>
      <div onClick={onClose} className={styles.serviceModalOverlay}></div>
    </>
  );
};

interface LandingServiceModalCardBaseProps {
  index: number;
  onModalOpen: () => void;
  text: JSX.Element;
}

export const LandingServiceModalCard = (props: LandingServiceModalCardBaseProps) => {
  const { onModalOpen, index, text } = props;
  const imgSrc = {
    1: img1,
    2: img3,
    3: img2,
    4: img4,
  }[index];

  return (
    <div>
      <div className={clsx(styles.serviceCardModalInner)}>
        <div className={clsx(styles.serviceCardModal)}>
          <div className={clsx(styles.serviceCardModalImg)}>
            <img src={imgSrc} alt="Иконка" />
          </div>
          <h4 className={styles.serviceCardModalHeader}>Что вам даёт сервис</h4>
          <div className={clsx(styles.serviceCardText, styles.serviceCardTextModal)}>{text}</div>

          <LandingButton variant="fancy" className={styles.serviceCardModalButton} onClick={onModalOpen}>
            Попробовать
          </LandingButton>
        </div>
      </div>
    </div>
  );
};

interface LandingServiceModalCardProps {
  onModalOpen: () => void;
}

export const LandingServiceModalCard1 = (props: LandingServiceModalCardProps) => {
  const { onModalOpen } = props;

  return (
    <LandingServiceModalCard
      index={1}
      onModalOpen={onModalOpen}
      text={
        <ul>
          <li>
            Контроль периодичности проведения инструктажей\проверок знаний по ОТ\медосмотров\психиатрических
            освидетельствований\стажировок.
          </li>
          <li>Автоматическое формирование пакета необходимых документов.</li>
          <li>Проведение оценки и управление профессиональными рисками.</li>
          <li>Формирование норм выдачи и личных карточек учета выдачи СИЗ и ДСИЗ.</li>
          <li>
            Контроль сроков и формирование документов, необходимых для проведения специальной оценки условий труда
          </li>
          <li>Напоминания о предстоящих событиях (провести мероприятие, подписать документы и т.д.).</li>
        </ul>
      }
    />
  );
};

export const LandingServiceModalCard2 = (props: LandingServiceModalCardProps) => {
  const { onModalOpen } = props;

  return (
    <LandingServiceModalCard
      index={2}
      onModalOpen={onModalOpen}
      text={
        <ul>
          <li>Контроль работы специалистов по охране труда.</li>
          <li>Постоянное наличие под рукой нужных документов для контролирующих органов.</li>
          <li>Автоматическое отслеживание выполнения требований законодательства в области охраны труда.</li>
          <li>Готовность к внеплановой проверке со стороны ГИТ, снижение риска получения предписания или штрафа.</li>
        </ul>
      }
    />
  );
};

export const LandingServiceModalCard3 = (props: LandingServiceModalCardProps) => {
  const { onModalOpen } = props;

  return (
    <LandingServiceModalCard
      index={3}
      onModalOpen={onModalOpen}
      text={
        <ul>
          <li>Возможность единовременной работы с большим количеством клиентов.</li>
          <li>
            Мониторинг периодичности проведения инструктажей\проверок знаний по ОТ\медосмотров\психиатрических
            освидетельствований\стажировок.
          </li>
          <li>Возможность контроля всех процессов Заказчиком внутри Системы в режиме реального времени.</li>
          <li>Кратное снижение трудозатрат на разработку и формирование необходимых документов.</li>
        </ul>
      }
    />
  );
};

export const LandingServiceModalCard4 = (props: LandingServiceModalCardProps) => {
  const { onModalOpen } = props;

  return (
    <LandingServiceModalCard
      index={4}
      onModalOpen={onModalOpen}
      text={
        <ul>
          <li>Контроль работы специалистов по охране труда.</li>
          <li>Постоянное наличие под рукой нужных документов для контролирующих органов.</li>
          <li>Автоматическое отслеживание выполнения требований законодательства в области охраны труда.</li>
          <li>Готовность к внеплановой проверке со стороны ГИТ, снижение риска получения предписания или штрафа.</li>
        </ul>
      }
    />
  );
};

interface LandingServiceModalCardWrapperProps {
  index: number;
  onModalOpen: () => void;
}

export const LandingServiceModalCardWrapper = (props: LandingServiceModalCardWrapperProps) => {
  const { index, onModalOpen } = props;
  switch (index) {
    case 1:
      return <LandingServiceModalCard1 onModalOpen={onModalOpen} />;
    case 2:
      return <LandingServiceModalCard2 onModalOpen={onModalOpen} />;
    case 3:
      return <LandingServiceModalCard3 onModalOpen={onModalOpen} />;
    case 4:
      return <LandingServiceModalCard4 onModalOpen={onModalOpen} />;
    default:
      return <LandingServiceModalCard4 onModalOpen={onModalOpen} />;
  }
};
