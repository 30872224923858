import clsx from 'clsx';

import stylesCommon from '../../Landing.module.scss';
import styles from './LandingHelpsWith.module.scss';
import { HelpsWithIcon1 } from './HelpsWithIcon1';
import { HelpsWithIcon2 } from './HelpsWithIcon2';
import { HelpsWithIcon3 } from './HelpsWithIcon3';
import { HelpsWithIcon4 } from './HelpsWithIcon4';
import { HelpsWithIcon5 } from './HelpsWithIcon5';

import img from '../../assets/images/helps-with-bg.png';
import { LANDING_SECTION_CLASS } from '@/landing/util';

export const LandingHelpsWith = () => {
  return (
    <div className={clsx(styles.zIndexWrapper, LANDING_SECTION_CLASS)} id="landing-helps-with">
      <div>
        <div className={clsx('landing__scroll-anchor-helps-with')}>
          <div className={clsx('', 'p-0')}>
            <div className={clsx(styles.wrapper)}>
              <div className={clsx(styles.wrapperBg)}>
                <img src={img} alt="Иконка" loading="lazy" />
              </div>
              <div className={clsx(stylesCommon.wrapper, styles.wrapperInner)}>
                <h2 className={styles.header}>ПРОГРАММНЫЙ КОМПЛЕКС ПОМОЖЕТ</h2>
                <div className={styles.cards}>
                  <div className={styles.card}>
                    <div className={styles.img}>
                      <HelpsWithIcon1 />
                    </div>
                    <div className={styles.text}>Сократить трудозатраты и повысить эффективность специалистов</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.img}>
                      <HelpsWithIcon2 />
                    </div>
                    <div className={styles.text}>Снизить уровни профессиональных рисков</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.img}>
                      <HelpsWithIcon3 />
                    </div>
                    <div className={styles.text}>Сократить количество несчастных случаев и травм</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.img}>
                      <HelpsWithIcon4 />
                    </div>
                    <div className={styles.text}>Минимизировать штрафы</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.img}>
                      <HelpsWithIcon5 />
                    </div>
                    <div className={styles.text}>Повысить репутацию компании</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
