export const LandingFunctionalityIcon2 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1543_313)">
        <path
          d="M37.8379 24.6034L41.6433 10.9432C41.9359 9.89271 43.0247 9.27836 44.0753 9.57096C45.1257 9.86355 45.7401 10.9524 45.4475 12.0029L41.6421 25.6631"
          fill="url(#paint0_linear_1543_313)"
        />
        <path
          d="M44.075 9.57102C43.5129 9.41446 42.941 9.51937 42.4849 9.80999C43.1966 10.2652 43.5609 11.1467 43.3223 12.0029L39.6699 25.1138L41.6417 25.6631L45.4472 12.0029C45.7398 10.9524 45.1254 9.86362 44.075 9.57102Z"
          fill="url(#paint1_linear_1543_313)"
        />
        <path
          d="M34.0342 23.5435L38.3695 7.98126C38.6621 6.93079 39.7509 6.31645 40.8014 6.60904C41.8519 6.90164 42.4662 7.99045 42.1737 9.04101L37.8384 24.6032"
          fill="url(#paint2_linear_1543_313)"
        />
        <path
          d="M40.8011 6.60911C40.2391 6.45255 39.6672 6.55745 39.211 6.84808C39.9228 7.30333 40.2871 8.18476 40.0485 9.04098L35.8662 24.0539L37.838 24.6032L42.1733 9.04108C42.4659 7.99061 41.8516 6.9018 40.8011 6.60911Z"
          fill="url(#paint3_linear_1543_313)"
        />
        <path
          d="M34.0338 23.5437L37.5744 10.8345C37.8671 9.784 37.2526 8.69519 36.2022 8.4025C35.1517 8.1099 34.0629 8.72425 33.7702 9.77472L29.6519 24.5715C29.4318 25.3623 28.3176 25.3822 28.0694 24.5996L26.603 19.9747C26.2717 18.9358 25.1609 18.3621 24.122 18.6933C23.0831 19.0247 22.5094 20.1354 22.8406 21.1743L27.103 34.3767L22.5856 42.6007C22.2859 43.1464 22.5732 43.8289 23.173 43.9961L39.1102 48.4359C39.696 48.5991 40.2852 48.1867 40.3322 47.5804L41.0707 38.0816C42.3633 36.3164 43.3264 34.3321 43.9136 32.2245L47.9269 17.818C48.2195 16.7675 47.6052 15.6787 46.5547 15.386C45.5042 15.0934 44.4154 15.7078 44.1227 16.7582L41.6419 25.6633"
          fill="url(#paint4_linear_1543_313)"
        />
        <path
          d="M35.4498 10.8345L32.1128 23.0231L34.0341 23.5438L37.5746 10.8345C37.8673 9.78407 37.2528 8.69525 36.2024 8.40257C35.6403 8.246 35.0684 8.35091 34.6123 8.64153C35.324 9.09688 35.6883 9.97832 35.4498 10.8345Z"
          fill="url(#paint5_linear_1543_313)"
        />
        <path
          d="M24.4784 19.9747L25.9449 24.5996C26.193 25.3822 27.3072 25.3623 27.5274 24.5715L27.7767 23.6757L26.6032 19.9748C26.2719 18.9359 25.1611 18.3622 24.1222 18.6934C23.9554 18.7466 23.8021 18.8218 23.6611 18.9116C24.036 19.151 24.3328 19.5177 24.4784 19.9747Z"
          fill="url(#paint6_linear_1543_313)"
        />
        <path
          d="M46.5547 15.386C45.9927 15.2294 45.4208 15.3343 44.9646 15.6249C45.6763 16.0802 46.0407 16.9616 45.8021 17.8178L41.7887 32.2243C41.2016 34.332 40.2385 36.3162 38.9458 38.0814L38.2074 47.5803C38.1911 47.7898 38.109 47.9751 37.9858 48.1226L39.11 48.4357C39.6958 48.599 40.285 48.1866 40.332 47.5803L41.0705 38.0814C42.3631 36.3162 43.3262 34.3319 43.9134 32.2243L47.9267 17.8178C48.2195 16.7674 47.6052 15.6786 46.5547 15.386Z"
          fill="url(#paint7_linear_1543_313)"
        />
        <path
          d="M22.5856 42.6005C22.2859 43.1462 22.5732 43.8287 23.173 43.9959L39.1102 48.4357C39.696 48.5989 40.2852 48.1865 40.3322 47.5802L40.5612 44.6357L23.991 40.042L22.5856 42.6005Z"
          fill="url(#paint8_linear_1543_313)"
        />
        <path
          d="M9.2355 18.7137L7.07868 4.69839C6.91284 3.62064 5.90465 2.88133 4.82681 3.04717C3.74906 3.21301 3.00975 4.2212 3.17559 5.29905L5.3325 19.3144"
          fill="url(#paint9_linear_1543_313)"
        />
        <path
          d="M7.07838 4.69839C6.91254 3.62064 5.90435 2.88133 4.8266 3.04717C4.54563 3.09039 4.28848 3.19192 4.06348 3.33601C4.52529 3.63105 4.86373 4.11414 4.95363 4.6983L7.09845 18.9668L9.23519 18.7137L7.07838 4.69839Z"
          fill="url(#paint10_linear_1543_313)"
        />
        <path
          d="M13.1383 18.113L10.6811 2.14614C10.5153 1.06839 9.5071 0.329081 8.42926 0.494925C7.35151 0.660769 6.61229 1.66896 6.77813 2.7468L9.23532 18.7136"
          fill="url(#paint11_linear_1543_313)"
        />
        <path
          d="M10.6888 2.19686C10.5319 1.17704 9.63392 0.409137 8.60417 0.4757C8.25448 0.498293 7.93686 0.610137 7.66602 0.783668C8.12783 1.0787 8.46627 1.56179 8.55617 2.14595L11.0134 18.1128C11.0134 18.1128 11.031 18.2106 11.0581 18.387L13.1381 18.1128L10.6888 2.19686Z"
          fill="url(#paint12_linear_1543_313)"
        />
        <path
          d="M13.1383 18.1131L11.1316 5.07346C10.9657 3.99571 11.705 2.98752 12.7828 2.82159C13.8606 2.65574 14.8687 3.39496 15.0347 4.47281L17.3673 19.6538C17.492 20.4652 18.596 20.6172 18.9352 19.8696L20.9403 15.4516C21.3925 14.4593 22.5636 14.0215 23.5558 14.4739C24.5481 14.9261 24.9859 16.0971 24.5335 17.0894L18.7342 29.6924L22.2432 38.3945C22.4761 38.9719 22.1097 39.6156 21.4944 39.7102L5.14284 42.2265C4.54172 42.319 4.00584 41.8396 4.03097 41.232L4.42537 31.7127C3.35147 29.8065 2.63072 27.722 2.298 25.5595L0.0232474 10.7784C-0.142596 9.70068 0.596623 8.69249 1.67447 8.52656C2.75222 8.36071 3.7604 9.09993 3.92634 10.1778L5.3324 19.3144"
          fill="url(#paint13_linear_1543_313)"
        />
        <path
          d="M1.80129 10.1774L3.20735 19.314L5.28298 18.9946L3.92604 10.1774C3.7602 9.09964 2.75201 8.36033 1.67426 8.52617C1.39329 8.56939 1.13613 8.67092 0.911133 8.81502C1.37295 9.11005 1.71138 9.59314 1.80129 10.1774Z"
          fill="url(#paint14_linear_1543_313)"
        />
        <path
          d="M12.9098 4.4728L15.2424 19.6538C15.5008 20.8984 17.4325 20.0291 17.4325 20.0291L15.0345 4.4728C14.8687 3.39505 13.8605 2.65574 12.7827 2.82159C12.5017 2.8648 12.2445 2.96633 12.0195 3.11043C12.4813 3.40546 12.8199 3.88855 12.9098 4.4728Z"
          fill="url(#paint15_linear_1543_313)"
        />
        <path
          d="M23.5558 14.4737C22.9234 14.1855 22.219 14.26 21.6737 14.6081C22.4906 15.1286 22.822 16.1827 22.4088 17.0893L16.6094 29.6923L20.1185 38.3944C20.3513 38.9718 19.985 39.6155 19.3697 39.7102L4.38281 42.0165C4.58597 42.1839 4.85662 42.2705 5.14284 42.2265L21.4944 39.71C22.1098 39.6153 22.4761 38.9717 22.2432 38.3942L18.7342 29.6921L24.5335 17.0891C24.9858 16.097 24.5481 14.926 23.5558 14.4737Z"
          fill="url(#paint16_linear_1543_313)"
        />
        <path
          d="M4.03113 41.2317C4.00591 41.8394 4.54188 42.3187 5.143 42.2262L21.4946 39.7097C22.11 39.6151 22.4763 38.9714 22.2434 38.394L21.1784 35.7529L4.15432 38.2564L4.03113 41.2317Z"
          fill="url(#paint17_linear_1543_313)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1543_313"
          x1="41.6791"
          y1="9.49805"
          x2="41.6791"
          y2="25.6631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1543_313"
          x1="42.595"
          y1="9.49854"
          x2="42.595"
          y2="25.6631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1543_313"
          x1="38.1404"
          y1="6.53613"
          x2="38.1404"
          y2="24.6032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1543_313"
          x1="39.0562"
          y1="6.53662"
          x2="39.0562"
          y2="24.6032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1543_313"
          x1="35.2328"
          y1="8.32959"
          x2="35.2328"
          y2="48.4718"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1543_313"
          x1="34.8802"
          y1="8.33008"
          x2="34.8802"
          y2="23.5438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1543_313"
          x1="25.7189"
          y1="18.5996"
          x2="25.7189"
          y2="25.1757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1543_313"
          x1="42.9928"
          y1="15.3135"
          x2="42.9928"
          y2="48.4716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1543_313"
          x1="31.5135"
          y1="40.042"
          x2="31.5135"
          y2="48.4716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1543_313"
          x1="6.19392"
          y1="3.02393"
          x2="6.19392"
          y2="19.3144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1543_313"
          x1="6.64934"
          y1="3.02393"
          x2="6.64934"
          y2="18.9668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1543_313"
          x1="9.9466"
          y1="0.47168"
          x2="9.9466"
          y2="18.7136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1543_313"
          x1="10.4021"
          y1="0.47168"
          x2="10.4021"
          y2="18.387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1543_313"
          x1="12.3559"
          y1="2.79834"
          x2="12.3559"
          y2="42.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1543_313"
          x1="3.09705"
          y1="8.50293"
          x2="3.09705"
          y2="19.314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1543_313"
          x1="14.726"
          y1="2.79834"
          x2="14.726"
          y2="20.3244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1543_313"
          x1="14.5473"
          y1="14.2959"
          x2="14.5473"
          y2="42.2379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1543_313"
          x1="13.1722"
          y1="35.7529"
          x2="13.1722"
          y2="42.2377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <clipPath id="clip0_1543_313">
          <rect width="48" height="48" fill="white" transform="translate(0 0.47168)" />
        </clipPath>
      </defs>
    </svg>
  );
};
