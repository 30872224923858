export const LandingChevronRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4044 9.91625L6.16022 4.67208C5.83522 4.34708 5.83522 3.81875 6.16022 3.49375C6.48522 3.16875 7.01355 3.16875 7.33855 3.49375L13.1719 9.32708C13.4977 9.65208 13.4977 10.1804 13.1719 10.5054L7.33855 16.3387C7.01355 16.6637 6.48522 16.6637 6.16022 16.3387C5.83522 16.0138 5.83522 15.4854 6.16022 15.1604L11.4044 9.91625Z"
        fill="currentColor"
      />
    </svg>
  );
};
