export const LandingFunctionalityIcon6 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1484_970)">
        <path
          d="M39.9402 20.9312V33.0103C38.2109 34.3956 36.3469 35.525 34.3976 36.3988C33.9127 36.6163 33.4227 36.8181 32.928 37.0042C29.0637 38.4551 24.9259 38.9407 20.8793 38.4609C16.2416 37.9113 11.724 36.0941 7.87402 33.0103V20.9312H39.9402Z"
          fill="url(#paint0_linear_1484_970)"
        />
        <path
          d="M15.7352 20.9312V37.3059C12.9425 36.3684 10.278 34.9362 7.87305 33.0103V20.9312H15.7352Z"
          fill="url(#paint1_linear_1484_970)"
        />
        <path
          d="M34.3969 23.4894V36.3983C33.912 36.6158 33.422 36.8176 32.9272 37.0037V23.4894C32.9272 22.9924 33.2565 22.5889 33.6622 22.5889C34.0676 22.5889 34.3969 22.9924 34.3969 23.4894Z"
          fill="url(#paint2_linear_1484_970)"
        />
        <path
          d="M39.9392 20.9307V21.9103L23.9061 28.0227L7.87305 21.9103V20.9307H39.9392Z"
          fill="url(#paint3_linear_1484_970)"
        />
        <path
          d="M47.9067 14.5378V17.8931L23.9067 27.0432L-0.0932617 17.8931V14.5378L23.9067 5.3877L47.9067 14.5378Z"
          fill="url(#paint4_linear_1484_970)"
        />
        <path
          d="M23.9067 23.6886V27.0435L-0.0932617 17.8933V14.5381L23.9067 23.6886Z"
          fill="url(#paint5_linear_1484_970)"
        />
        <path
          d="M36.2372 41.0272C35.8314 41.0272 35.5026 40.698 35.5026 40.2923V19.4611L23.6037 14.0734C23.2342 13.906 23.0701 13.4706 23.2374 13.1007C23.4048 12.7312 23.8399 12.5672 24.2101 12.7345L36.5404 18.3178C36.8034 18.4368 36.9718 18.6986 36.9718 18.9868V40.2923C36.9718 40.698 36.643 41.0272 36.2372 41.0272Z"
          fill="url(#paint6_linear_1484_970)"
        />
        <path
          d="M38.2946 42.3499C38.2946 43.4858 37.3735 44.4069 36.2375 44.4069C35.1012 44.4069 34.1802 43.4858 34.1802 42.3499C34.1802 41.2135 35.1012 40.2925 36.2375 40.2925C37.3735 40.2925 38.2946 41.2135 38.2946 42.3499Z"
          fill="url(#paint7_linear_1484_970)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1484_970"
          x1="23.9071"
          y1="20.9312"
          x2="23.9071"
          y2="38.6397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_970"
          x1="11.8041"
          y1="20.9312"
          x2="11.8041"
          y2="37.3059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_970"
          x1="33.662"
          y1="22.5889"
          x2="33.662"
          y2="37.0037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1484_970"
          x1="23.9061"
          y1="20.9307"
          x2="23.9061"
          y2="28.0227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1484_970"
          x1="23.9067"
          y1="5.3877"
          x2="23.9067"
          y2="27.0432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1484_970"
          x1="11.9067"
          y1="14.5381"
          x2="11.9067"
          y2="27.0435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1484_970"
          x1="30.0718"
          y1="12.6689"
          x2="30.0718"
          y2="41.0272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1484_970"
          x1="36.2374"
          y1="40.2925"
          x2="36.2374"
          y2="44.4069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <clipPath id="clip0_1484_970">
          <rect width="48" height="48" fill="white" transform="translate(0 0.887695)" />
        </clipPath>
      </defs>
    </svg>
  );
};
