import clsx from 'clsx';
import styles from './LandingModal.module.scss';
import { type PropsWithChildren } from 'react';
import { LandingCloseIcon } from '../icons/LandingCloseIcon';

interface Props extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  classNames?: {
    modalInner?: string;
  };
}

export const LandingModal = (props: Props) => {
  const { open, onClose, children, classNames = {} } = props;
  if (!open) {
    return null;
  }
  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalCloseBtn} onClick={onClose}>
          <LandingCloseIcon />
        </div>
        <div className={clsx(styles.modalInner, classNames.modalInner)}>{children}</div>
      </div>
      {open && <div className={styles.modalOverlay} onClick={onClose} />}
    </>
  );
};
