export const LandingUserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.0001 9.79167C12.1862 9.79167 13.9584 8.01946 13.9584 5.83333C13.9584 3.64721 12.1862 1.875 10.0001 1.875C7.81395 1.875 6.04175 3.64721 6.04175 5.83333C6.04175 8.01946 7.81395 9.79167 10.0001 9.79167Z"
        fill="white"
      />
      <path
        d="M17.0584 14.4333L16.9501 14.1666C16.586 13.2519 15.96 12.4651 15.1506 11.9047C14.3412 11.3444 13.3843 11.0354 12.4001 11.0166H7.60839C6.62411 11.0354 5.66728 11.3444 4.85786 11.9047C4.04845 12.4651 3.42246 13.2519 3.05839 14.1666L2.94172 14.4249C2.75999 14.842 2.68393 15.2975 2.72027 15.751C2.7566 16.2045 2.90423 16.6421 3.15006 17.0249C3.35023 17.3518 3.63061 17.6222 3.96459 17.8103C4.29856 17.9985 4.67507 18.0981 5.05839 18.0999H14.9334C15.3178 18.098 15.6954 17.9983 16.0307 17.8102C16.366 17.6222 16.648 17.3519 16.8501 17.0249C17.0938 16.6428 17.2402 16.2068 17.2765 15.755C17.3128 15.3032 17.238 14.8494 17.0584 14.4333Z"
        fill="white"
      />
    </svg>
  );
};
