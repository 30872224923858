
import toast from '@/utils/toast';
import { useEffect } from 'react';
import { useApolloNetworkStatus } from '@/apollo/config';
import { selectAuthError, selectGlobalErrors } from '@/utils/errors';
import { deleteToken } from '@/utils/token';
import { useNavigate } from 'react-router-dom';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { ProfileDocument } from '@/gql/generated';

const useGlobalErrorHandler = () => {
  const [loadProfile] = useLazyQuery(ProfileDocument, { fetchPolicy: 'network-only' });
  const status = useApolloNetworkStatus();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { mutationError, queryError } = status;
  useEffect(() => {
    // first check authorization errors, since they are handled differently
    (async () => {
      const mutationAuthError = selectAuthError(mutationError?.graphQLErrors);
      const queryAuthError = selectAuthError(queryError?.graphQLErrors);

      if ((queryError && queryAuthError) ?? (mutationError && mutationAuthError)) {
        const error = queryError ?? mutationError;
        const authError = queryAuthError ?? mutationAuthError;
        const operation = error!.operation.operationName;
        // abortController.abort();
        if (operation === 'profile') {
          deleteToken();
        } else {
          await client.clearStore();
          const response = await loadProfile();
          if (response.error) {
            navigate('/auth');
          } else {
            toast.error(authError ?? '');
            navigate('/');
          }
        }
      }
    })();

    const mutationsErrors = selectGlobalErrors(mutationError?.graphQLErrors);
    mutationsErrors.forEach((message) => {
      toast.error(message, {
        toastId: message
      });
    });

    const queryErrors = selectGlobalErrors(queryError?.graphQLErrors);
    queryErrors.forEach((message) => {
      toast.error(message, {
        toastId: message
      });
    });
  }, [mutationError, queryError]);
};

export default useGlobalErrorHandler;
