import { components, type GroupBase, type MenuListProps } from 'react-select';

import useScrollCapture from './useScrollCapture';
import { LoadingIndicator } from './LoadingIndicator';

import styles from './Select.module.scss';
import clsx from 'clsx';

/**
 * Fixes the problem with onMenuScrollToBottom not firing on mobile devices
 * https://github.com/JedWatson/react-select/issues/3232
 */
export const CustomMenuList = (props: MenuListProps<unknown, boolean, GroupBase<unknown>>) => {
  const { selectProps, getClassNames } = props;
  const { onMenuScrollToBottom, onMenuScrollToTop } = selectProps;

  const setTarget = useScrollCapture({
    isEnabled: true,
    onBottomArrive: onMenuScrollToBottom,
    onTopArrive: onMenuScrollToTop,
  });

  // Normally with react-select you would pass the innerRef from props to innerRef of the nested component,
  // because react-select uses it for its internal logic.
  // But since we're trying to bypass this logic, we "disconnect" react-select from
  // this component by creating our own ref instead.
  return (
    <components.MenuList
      {...props}
      innerRef={(instance) => {
        setTarget(instance);
      }}
      className={clsx(styles.menuList, getClassNames('menuList', props))}
    >
      {props.children}
      {(props as any).isLoading && <LoadingIndicator />}
    </components.MenuList>
  );
};
