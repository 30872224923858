import { useState } from 'react';
import clsx from 'clsx';
import { FunctionalityItem } from './FunctionalityItem';
import { LandingFunctionalityIcon1 } from '../icons/LandingFunctionalityIcon1';
import { LandingFunctionalityIcon2 } from '../icons/LandingFunctionalityIcon2';
import { LandingFunctionalityIcon4 } from '../icons/LandingFunctionalityIcon4';
import { LandingFunctionalityIcon3 } from '../icons/LandingFunctionalityIcon3';
import { LandingFunctionalityIcon5 } from '../icons/LandingFunctionalityIcon5';
import { LandingFunctionalityIcon6 } from '../icons/LandingFunctionalityIcon6';
import { LandingFunctionalityIcon7 } from '../icons/LandingFunctionalityIcon7';
import { LandingFunctionalityIcon8 } from '../icons/LandingFunctionalityIcon8';
import { LandingFunctionalityIcon9 } from '../icons/LandingFunctionalityIcon9';
import { LandingFunctionalityIcon10 } from '../icons/LandingFunctionalityIcon10';
import stylesCommon from '../../Landing.module.scss';
import styles from './LandingFunctionality.module.scss';
import { LANDING_SECTION_CLASS } from '@/landing/util';

const items = [
  {
    icon: <LandingFunctionalityIcon1 />,
    title: 'Оценка профессиональных рисков',
    description:
      'Инструмент для самостоятельной оценки и управления профессиональными рисками. Включает базу знаний, содержащую информацию более чем о 300 источниках опасности и дающую возможность автоматизированного формирования типового перечня опасностей для рабочего места в соответствии с наименованием должности либо профессии работника, используемым оборудованием, материалами и сырьем, а также с учетом результатов специальной оценки условий труда. Позволяет формировать комплект документов по результатам проведенных оценок. ',
  },
  {
    icon: <LandingFunctionalityIcon2 />,
    title: 'СИЗ и ДСИЗ',
    description:
      'Инструмент для определения перечня  средств, позволяющих обеспечить защиту работников от вредных факторов и опасностей, выявленных в результате оценки профессиональных рисков на рабочих местах с учетом требований законодательства, вступивших в силу с 01.09.2023. Позволяет формировать нормы выдачи, личные карточки учета выдачи СИЗ и карточки учета выдачи дежурных СИЗ.',
  },
  {
    icon: <LandingFunctionalityIcon3 />,
    title: 'Учет несчастных случаев и микротравм',
    description:
      'Позволяет автоматизировать процессы регистрации, учета и рассмотрения обстоятельств и причин несчастных случаев, и микроповреждений. Необходимые документы формируются автоматически. Функционал учета направлен на повышение эффективности в проведении системных мероприятий по управлению профессиональными рисками и применим при проведении оценки профессиональных рисков.',
  },
  {
    icon: <LandingFunctionalityIcon4 />,
    title: 'Вводный инструктаж',
    description:
      'Позволяет существенно упростить процессы организации и контроля проведения вводного инструктажа: необходимые документы на конкретного работника будут формироваться автоматически с учетом всех установленных требований.',
  },
  {
    icon: <LandingFunctionalityIcon5 />,
    title: 'Инструктаж по охране труда на рабочем месте',
    description:
      'Позволяет существенно упростить процессы организации и контроля проведения первичного, повторного, внепланового и целевого инструктажа по охране труда на рабочем месте: необходимые документы на конкретного работника будут формироваться автоматически с учетом всех установленных требований.',
  },
  {
    icon: <LandingFunctionalityIcon6 />,
    title: 'Стажировки',
    description:
      'Позволяет существенно упростить процессы организации и контроля прохождения работниками стажировок на рабочем месте: необходимые документы на каждого работника будут формироваться автоматически с учетом всех установленных требований.',
  },
  {
    icon: <LandingFunctionalityIcon7 />,
    title: 'Медицинские осмотры',
    description:
      'Позволяет существенно упростить процессы организации и контроля прохождения работниками медицинских осмотров: необходимые документы на каждого работника будут формироваться автоматически с учетом всех установленных требований.',
  },
  {
    icon: <LandingFunctionalityIcon8 />,
    title: 'Психиатрические освидетельствования',
    description:
      'Позволяет существенно упростить процессы организации и контроля прохождения работниками психиатрических освидетельствований: необходимые документы на каждого работника будут формироваться автоматически с учетом всех установленных требований.',
  },
  {
    icon: <LandingFunctionalityIcon9 />,
    title: 'СОУТ',
    description:
      'Позволяет контролировать сроки и планировать проведение специальной оценки условий труда, предоставляет возможность сформировать комплект документов, необходимых для ее организации.',
  },
  {
    icon: <LandingFunctionalityIcon10 />,
    title: 'ЛНА',
    description:
      'Позволяет автоматизировать процессы ознакомления работников с локальными нормативными актами организации.',
    isCollapsed: false,
  },
];

export const LandingFunctionality = () => {
  const [itemExpandedIndex, setItemExpandedIndex] = useState<number | null>(null);

  return (
    <div className={clsx(styles.functionality, LANDING_SECTION_CLASS)} id="landing-functionality">
      <div className={stylesCommon.wrapper}>
        <h2 className={styles.functionalityHeader}>Описание функционала</h2>
        <div className={clsx(styles.functionalityText)}>
          <p>
            Функционирование системы управления охраной труда будет обеспечиваться за счет минимальных усилий со стороны
            ответственных лиц
          </p>
        </div>
        <div className={clsx(itemExpandedIndex !== 0 && styles.functionalityItems)}>
          {items.map((item, index) => (
            <FunctionalityItem
              key={index}
              icon={item.icon}
              title={item.title}
              description={item.description}
              expanded={itemExpandedIndex === index}
              isCollapsedItem={item.isCollapsed}
              onExpendBtn={() => {
                setItemExpandedIndex(index);
              }}
              removeBorder={itemExpandedIndex !== null && index === itemExpandedIndex - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
