export const LandingFunctionalityIcon5 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1484_550)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.6987 34.8623H4.30326C3.85335 34.8623 3.48779 34.4968 3.48779 34.0469C3.48779 24.0926 9.87092 15.4599 19.0098 13.0416C19.1504 13.0135 28.8516 13.0135 28.9922 13.0416C38.131 15.4599 44.5142 24.0926 44.5142 34.0469C44.5142 34.4968 44.1486 34.8623 43.6987 34.8623Z"
          fill="url(#paint0_linear_1484_550)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.08398 19.1434C9.44954 18.7497 9.84321 18.3561 10.2369 17.9624C13.2738 20.0151 15.8608 24.2612 15.8608 27.748C15.8608 28.3385 15.8045 28.9571 15.6921 29.5476C15.6358 29.9413 15.2703 30.1944 14.9047 30.1944C14.8485 30.1944 14.7922 30.1944 14.7641 30.1944C14.3142 30.11 14.0049 29.6882 14.0893 29.2383C14.1736 28.7603 14.2299 28.2541 14.2299 27.748C14.2299 24.7392 11.7553 20.8306 9.08398 19.1434ZM37.7378 17.9624C38.1596 18.3561 38.5251 18.7497 38.8907 19.1434C36.2474 20.8306 33.7729 24.7392 33.7729 27.748C33.7729 28.2541 33.801 28.7603 33.9135 29.2383C33.9698 29.6882 33.6886 30.11 33.2386 30.1944C33.1824 30.1944 33.1543 30.1944 33.098 30.1944C32.7044 30.1944 32.3669 29.9413 32.2826 29.5476C32.1982 28.9571 32.142 28.3385 32.142 27.748C32.142 24.2612 34.729 20.0151 37.7378 17.9624Z"
          fill="url(#paint1_linear_1484_550)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.186 41.6106H0.816931C0.367018 41.6106 0.00146484 41.2731 0.00146484 40.8232V37.4208C0.00146484 35.115 1.88547 33.231 4.19127 33.231H43.7835C46.1175 33.231 48.0015 35.115 48.0015 37.4208V40.8232C48.0015 41.2731 47.6359 41.6106 47.186 41.6106Z"
          fill="url(#paint2_linear_1484_550)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5147 18.5527C25.534 18.5527 22.5252 18.5527 19.5446 18.5527C19.0665 18.5808 18.701 18.2433 18.6729 17.7934L17.998 8.06409C17.9699 7.83913 18.0542 7.61418 18.223 7.44546C18.3636 7.27674 18.5885 7.19238 18.8135 7.19238H29.1896C29.4145 7.19238 29.6395 7.30486 29.7801 7.44546C29.9488 7.61418 30.005 7.83913 30.005 8.06409L29.3302 17.7934C29.302 18.2152 28.9365 18.5527 28.5147 18.5527Z"
          fill="url(#paint3_linear_1484_550)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9876 28.001C21.9911 28.001 20.332 26.342 20.332 24.3455C20.332 22.3209 21.9911 20.6899 23.9876 20.6899C26.0122 20.6899 27.6431 22.3209 27.6431 24.3455C27.6431 26.342 26.0122 28.001 23.9876 28.001Z"
          fill="url(#paint4_linear_1484_550)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1484_550"
          x1="24.001"
          y1="13.0205"
          x2="24.001"
          y2="34.8623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_550"
          x1="23.9873"
          y1="17.9624"
          x2="23.9873"
          y2="30.1944"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_550"
          x1="24.0015"
          y1="33.231"
          x2="24.0015"
          y2="41.6106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1484_550"
          x1="23.9989"
          y1="7.19238"
          x2="23.9989"
          y2="18.5543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1484_550"
          x1="23.9876"
          y1="20.6899"
          x2="23.9876"
          y2="28.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <clipPath id="clip0_1484_550">
          <rect width="48" height="48" fill="white" transform="translate(0 0.416016)" />
        </clipPath>
      </defs>
    </svg>
  );
};
