import type { GraphQLError } from 'graphql';
import type { ErrorOption } from 'react-hook-form';

interface ErrorOptionData {
  name: any;
  data: ErrorOption;
}

export const selectValidationErrors = (errors: Readonly<GraphQLError[]> = []): ErrorOptionData[] => {
  const result: ErrorOptionData[] = [];
  const validationError = errors.filter((error) => error.extensions.category === 'validation')[0];
  if (!validationError) {
    return result;
  }
  const errorsObj: any = validationError.extensions.validation ?? {};
  for (const name in errorsObj) {
    result.push({ name, data: { message: errorsObj[name].join(' ') } });
  }

  return result;
};

export const selectAuthError = (errors: Readonly<GraphQLError[]> = []): string | null => {
  const authError = errors.filter((error) => error.extensions.category === 'authorization')[0];
  if (!authError) {
    return null;
  }
  return authError.message;
};

export const handledLocally = ['authorization', 'validation'];

export const selectGlobalErrors = (errors: Readonly<GraphQLError[]> = []): string[] => {
  const globalErrors = errors.filter(
    (error) =>
      !handledLocally.includes(error.extensions.category as string) || (error.extensions.validation as any)?.project_id,
  );
  return globalErrors.map(({ message, debugMessage }: any) => (message === 'validation' ? debugMessage : message));
};
