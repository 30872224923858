import clsx from 'clsx';

import stylesCommon from '../../Landing.module.scss';
import styles from './LandingOffer.module.scss';
import { useEffect, useRef } from 'react';

import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from 'gsap/all';
import { LANDING_SECTION_CLASS } from '@/landing/util';
import { useWindowHeight } from '@react-hook/window-size';
gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);

export const LandingOffer = () => {
  const timelineRef = useRef<any>(null);
  const textRef = useRef(null);
  const wrapperRef = useRef(null);

  const windowHeight = useWindowHeight();

  useEffect(() => {
    if (!textRef.current || !wrapperRef.current) {
      return;
    }
    timelineRef.current?.map((item: any) => item.kill());

    const items = document.querySelectorAll(`.${styles.offerLeftTextItem}`);
    const numberOfLines = items.length;
    const start = windowHeight / 3;
    const end = start + windowHeight / 2;
    const step = (end - start) / numberOfLines;
    const timelines = [];

    for (let i = 0; i < numberOfLines; i++) {
      const scrollTimeline = gsap.timeline({
        scrollTrigger: {
          start: `bottom bottom-=${i * step}`,
          end: `top top`,
          endTrigger: wrapperRef.current,
          trigger: items[i] as HTMLElement,
          scrub: true,
        },
      });
      scrollTimeline.fromTo(items[i], { color: '#011135' }, { color: '#FCFCFD' });
      timelines.push(scrollTimeline);
    }

    timelineRef.current = timelines;

    return () => {
      timelineRef.current?.map((item: any) => item.kill());
    };
  }, [textRef.current, wrapperRef.current, windowHeight]);

  return (
    <div className={clsx('landing__offer-scroll', LANDING_SECTION_CLASS)}>
      <div className={clsx(stylesCommon.wrapper)} ref={wrapperRef}>
        <div className={clsx(styles.offer)}>
          <div className={styles.offerLeft}>
            <div className={styles.offerLeftText} id="landing-offer">
              <div className={styles.offerLeftTextItem} ref={textRef}>
                Предлагаем эффективные инструменты
              </div>{' '}
              <div className={styles.offerLeftTextItem}>для управления рисками,</div>{' '}
              <div className={styles.offerLeftTextItem}>контроля соблюдения нормативов</div>{' '}
              <div className={styles.offerLeftTextItem}>и повышения безопасности на рабочем месте</div>{' '}
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
