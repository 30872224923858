import { QuerySelect } from '@/components/Form/QuerySelect';
import styles from './LandingDadataSelect.module.scss';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { errorMessages } from '@/utils/form';
import { components } from 'react-select';
import { type AsyncSelectOption } from '@/components/Form/AsyncSelect/AsyncSelectProps';
import { gql } from '@apollo/client';

const LandingOrganizationsDocument = gql`
  query landingOrganizations($search: String!) {
    landingOrganizations(search: $search) {
      name
      inn
      kpp
      ogrn
    }
  }
`;

interface Props {
  onSelect: (data: any) => void;
}

export const LandingDadataSelect = (props: Props) => {
  const { onSelect } = props;
  const [search, setSearch] = useState('');
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors.organization?.message as string;
  return (
    <Controller
      name="organization"
      rules={{ required: errorMessages.required }}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            <QuerySelect
              query={LandingOrganizationsDocument}
              value={value}
              isMulti={false}
              onChange={(id: any, data: any) => {
                onChange(id);
                onSelect(data);
              }}
              classNames={{
                container: () => styles.input,
                control: () => styles.control,
                menu: () => styles.menu,
                singleValue: () => styles.singleValue,
                placeholder: () => styles.placeholder,
              }}
              onInputChange={(value) => {
                setSearch(value);
              }}
              queryVars={{ search }}
              transformItem={(item: any) => ({
                value: item.inn,
                label: item.name,
              })}
              placeholder="Название, ИНН или ОГРН"
              noOptionsMessage={() => <div>Название, ИНН или ОГРН</div>}
              components={{
                Option: (props) => {
                  const { data } = props;
                  const { value, label } = data as AsyncSelectOption;
                  return (
                    <components.Option {...props}>
                      <div>{label}</div>
                      <small className={styles.inn}>ИНН: {value}</small>
                    </components.Option>
                  );
                },
              }}
            />
            {!!error && (
              <div className={styles.inputError}>
                <small>{error}</small>
              </div>
            )}
          </>
        );
      }}
    />
  );
};
