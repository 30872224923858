import styles from '@/components/Form/Select/Select.module.scss';

export const MultiValueRemove = (props: any) => {
  return (
    <span {...props.innerProps} className={styles.itemRemove} aria-label="select-multi-value-remove">
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 3.5L3 9.5M3 3.5L9 9.5"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
