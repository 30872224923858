/* eslint-disable */

import { forwardRef, useEffect, useRef } from 'react';
import { Form } from '../Form';
import { type FormControlProps } from 'react-bootstrap';

import styles from './Textarea.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWithErrorTooltip } from '../InputWithErrorTooltip';

export interface TextareaConnectedProps extends Omit<TextareaProps, 'onChange'> {
  name: string;
  rules?: any;
  rowClassName?: string;
  labelText?: string;
}

export type TextareaProps = FormControlProps & {
  value?: string;
  onChange?: (value: string) => void;
  autoHeight?: boolean;
  className?: string;
  rows?: number;
  minHeight?: number;
};

const MIN_INPUT_HEIGHT = 20;

const handleKeyDown = (target: HTMLTextAreaElement, min: number) => {
  target.style.height = 'inherit';
  const computed = window.getComputedStyle(target);
  const height =
    parseInt(computed.getPropertyValue('border-top-width'), 10) +
    target.scrollHeight +
    parseInt(computed.getPropertyValue('border-bottom-width'), 10);
  target.style.height = `${Math.max(height, MIN_INPUT_HEIGHT)}px`;
};

export const Textarea = forwardRef((props: TextareaProps, forwardedRef) => {
  const {
    value,
    onChange = () => {},
    autoHeight,
    className,
    rows = 1,
    minHeight = MIN_INPUT_HEIGHT,
    ...restProps
  } = props;
  const ref = useRef<HTMLTextAreaElement>();

  const updateHeight = () => {
    if (!ref.current || !autoHeight) {
      return;
    }
    handleKeyDown(ref.current, MIN_INPUT_HEIGHT);
  };

  const onKeyUp = () => {
    updateHeight();
  };

  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 10);
  });

  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 10);
  }, [ref.current, value]);

  return (
    <Form.Control
      as="textarea"
      size="sm"
      value={value ?? ''}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      ref={ref}
      onKeyUp={onKeyUp}
      className={`${styles.textarea} ${className ?? ''}`}
      rows={rows}
      {...restProps}
    />
  );
});

Textarea.displayName = 'Textarea';

export const TextareaRowWithErrorConnected = (props: TextareaConnectedProps) => {
  const { name, labelText, rowClassName = 'form-row', rules, ...restProps } = props;

  const methods = useFormContext();
  const {
    formState: { errors },
  } = methods;
  const errorMessage = errors[name]?.message as string;

  return (
    <div className={rowClassName}>
      {labelText && <label className="form-row__label">{labelText}</label>}
      <div className="form-row__input">
        <Controller
          name={name}
          rules={rules}
          render={({ field: { onChange, value } }) => {
            return (
              <InputWithErrorTooltip isInvalid={!!errorMessage} tooltipText={errorMessage}>
                <Textarea
                  {...restProps}
                  value={value}
                  onChange={onChange}
                  autoHeight={true}
                  className="pt-2"
                  isInvalid={!!errorMessage}
                />
              </InputWithErrorTooltip>
            );
          }}
        />
      </div>
    </div>
  );
};

export const TextareaWithErrorConnected = (props: TextareaConnectedProps) => {
  const { name, rules, ...restProps } = props;

  const methods = useFormContext();
  const {
    formState: { errors },
  } = methods;
  const errorMessage = errors[name]?.message as string;

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <InputWithErrorTooltip isInvalid={!!errorMessage} tooltipText={errorMessage}>
            <Textarea
              {...restProps}
              value={value}
              onChange={onChange}
              autoHeight={true}
              className="pt-2"
              isInvalid={!!errorMessage}
            />
          </InputWithErrorTooltip>
        );
      }}
    />
  );
};
