export const LandingFunctionalityIcon10 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1484_1394)">
        <path
          d="M14.8574 9.00445L20.5716 12.2045L17.3715 6.49016L20.5716 0.775879L14.8574 3.97588L9.14307 0.775879L12.3431 6.49016L9.14307 12.2045L14.8574 9.00445Z"
          fill="black"
        />
        <path
          d="M42.2861 31.4046L36.5718 28.2046L39.7718 33.9189L36.5718 39.6332L42.2861 36.4332L48.0003 39.6332L44.8003 33.9189L48.0003 28.2046L42.2861 31.4046Z"
          fill="black"
        />
        <path
          d="M48.0003 0.775879L42.2861 3.97588L36.5718 0.775879L39.7718 6.49016L36.5718 12.2045L42.2861 9.00445L48.0003 12.2045L44.8003 6.49016L48.0003 0.775879Z"
          fill="black"
        />
        <path
          d="M28.1145 25.4617L23.3145 20.6617L28.8002 15.176L33.6002 19.976L28.1145 25.4617Z"
          fill="url(#paint0_linear_1484_1394)"
        />
        <path
          d="M28.1145 25.4617L23.3145 20.6617L28.8002 15.176L33.6002 19.976L28.1145 25.4617Z"
          fill="url(#paint1_linear_1484_1394)"
        />
        <path
          d="M14.8574 9.00445L20.5716 12.2045L17.3715 6.49016L20.5716 0.775879L14.8574 3.97588L9.14307 0.775879L12.3431 6.49016L9.14307 12.2045L14.8574 9.00445Z"
          fill="url(#paint2_linear_1484_1394)"
        />
        <path
          d="M42.2861 31.4046L36.5718 28.2046L39.7718 33.9189L36.5718 39.6332L42.2861 36.4332L48.0003 39.6332L44.8003 33.9189L48.0003 28.2046L42.2861 31.4046Z"
          fill="url(#paint3_linear_1484_1394)"
        />
        <path
          d="M48.0003 0.775879L42.2861 3.97588L36.5718 0.775879L39.7718 6.49016L36.5718 12.2045L42.2861 9.00445L48.0003 12.2045L44.8003 6.49016L48.0003 0.775879Z"
          fill="url(#paint4_linear_1484_1394)"
        />
        <path
          d="M30.6287 12.8903C29.7144 11.976 28.3429 11.976 27.4287 12.8903L0.685714 39.6332C-0.228571 40.5474 -0.228571 41.9189 0.685714 42.8332L5.94286 48.0902C6.85714 49.0045 8.22857 49.0045 9.14286 48.0902L35.8858 21.3474C36.8001 20.4332 36.8001 19.0617 35.8858 18.1474L30.6287 12.8903ZM28.1144 25.4617L23.3144 20.6617L28.8001 15.176L33.6001 19.976L28.1144 25.4617Z"
          fill="url(#paint5_linear_1484_1394)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1484_1394"
          x1="29.943"
          y1="12.2046"
          x2="29.943"
          y2="25.4617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_1394"
          x1="29.943"
          y1="12.2046"
          x2="29.943"
          y2="25.4617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_1394"
          x1="14.8574"
          y1="0.775879"
          x2="14.8574"
          y2="12.2045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1484_1394"
          x1="42.2861"
          y1="28.2046"
          x2="42.2861"
          y2="39.6332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1484_1394"
          x1="42.2861"
          y1="0.775879"
          x2="42.2861"
          y2="12.2045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1484_1394"
          x1="18.2858"
          y1="12.2046"
          x2="18.2858"
          y2="48.7759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <clipPath id="clip0_1484_1394">
          <rect width="48" height="48" fill="white" transform="translate(0 0.775879)" />
        </clipPath>
      </defs>
    </svg>
  );
};
