import { useQuery } from '@apollo/client';
import { ProfileDocument } from '@/gql/generated';

export enum Role {
  Admin = 'admin',
  Organization = 'organization',
}

export const useCurrentRole = () => {
  const profileResponse = useQuery(ProfileDocument);
  return profileResponse.data?.profile.is_admin_account ? Role.Admin : Role.Organization;
};
