import { type ToastOptions, toast } from 'react-toastify';
import { ToastError, ToastSuccess } from '@/components/Toast/Toast';

export default {
  error: (message: string = '', props: ToastOptions = {}) => {
    if (!message) {
      return;
    }
    toast.error(<ToastError text={message} />, {
      className: 'toastify-toast-wrapper',
      bodyClassName: 'toastify-toast-body',
      ...props,
    });
  },
  success: (message: string = '', props: ToastOptions = {}) => {
    if (!message) {
      return;
    }
    toast.success(<ToastSuccess text={message} />, {
      className: 'toastify-toast-wrapper',
      bodyClassName: 'toastify-toast-body',
      ...props,
    });
  },
};
