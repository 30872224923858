import { useFormContext } from 'react-hook-form';
import styles from './ModalCheckbox.module.scss';

interface Props {
  name: string;
  rules?: any;
}

export const ModalCheckbox = (props: Props) => {
  return <input type="checkbox" className={styles.checkbox} />;
};

export const ModalCheckboxConnected = (props: Props) => {
  const { name, rules } = props;
  const { register } = useFormContext();
  return <input type="checkbox" className={styles.checkbox} {...register(name, rules)} />;
};
