import { forwardRef } from 'react';

export const ThreeDotsIcon = forwardRef((props: any, ref: any) => {
  return (
    <svg width="2.5em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 16C10 17.1046 9.10457 18 8 18C6.89543 18 6 17.1046 6 16C6 14.8954 6.89543 14 8 14C9.10457 14 10 14.8954 10 16Z"
        fill="currentColor"
      />
      <path
        d="M18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16Z"
        fill="currentColor"
      />
      <path
        d="M26 16C26 17.1046 25.1046 18 24 18C22.8954 18 22 17.1046 22 16C22 14.8954 22.8954 14 24 14C25.1046 14 26 14.8954 26 16Z"
        fill="currentColor"
      />
    </svg>
  );
});

ThreeDotsIcon.displayName = 'ThreeDotsIcon';
