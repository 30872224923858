import clsx from 'clsx';
import styles from './LandingHelp.module.scss';
import img from '../../assets/images/help-img.webp';
import { LandingHelpIcon2 } from '../icons/LandingHelpIcon2';
import { LandingHelpIcon3 } from '../icons/LandingHelpIcon3';
import { LandingHelpIcon4 } from '../icons/LandingHelpIcon4';
import { LandingHelpIcon5 } from '../icons/LandingHelpIcon5';
import { LANDING_SECTION_CLASS } from '@/landing/util';

const cardClassName = 'landingHelpCard';

export const LandingHelp = () => {
  return (
    <div className={clsx(LANDING_SECTION_CLASS)}>
      <div className={clsx(styles.help)} id="landing-help">
        <div>
          <div>
            <div className={styles.helpInner}>
              <h2 className={styles.helpHeader}>Как мы помогаем</h2>
              <div className={styles.helpText}>
                Функционирование системы управления охраной труда будет обеспечиваться за счет минимальных усилий со
                стороны ответственных лиц.
              </div>

              <div className="d-flex justify-content-center">
                <div className={styles.imgWrapper}>
                  <div className={styles.imgBorder}>
                    <img src={img} alt="Иллюстрация" />
                  </div>

                  <div className={styles.helpCardGrid}>
                    <div className={clsx(styles.helpCard, `${cardClassName}1`)}>
                      <div className={styles.helpCardText}>Автоматизация трудоемких процессов</div>
                      <LandingHelpIcon2 />
                    </div>

                    <div className={clsx(styles.helpCard, `${cardClassName}2`)}>
                      <div className={styles.helpCardText}>Оценка и управление проффесиональными рисками</div>
                      <LandingHelpIcon3 />
                    </div>

                    <div className={clsx(styles.helpCard, `${cardClassName}3`)}>
                      <div className={styles.helpCardText}>Формирование перечня необходимых средств защиты</div>
                      <LandingHelpIcon4 />
                    </div>

                    <div className={clsx(styles.helpCard, `${cardClassName}4`)}>
                      <div className={styles.helpCardText}>Учет несчастных случаев и микротравм</div>
                      <LandingHelpIcon5 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
