export const LandingFunctionalityIcon4 = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1484_161)">
        <path
          d="M8.13281 45.227V34.6978L40.0805 35.9728C38.9541 38.5133 38.9541 41.4116 40.0805 43.952L8.13281 45.227Z"
          fill="url(#paint0_linear_1484_161)"
        />
        <path d="M16.3853 38.6323H20.1189V40.5635H16.3853V38.6323Z" fill="url(#paint1_linear_1484_161)" />
        <path
          d="M41.5878 44.4157V46.5836C41.5878 46.8398 41.3801 47.0475 41.1239 47.0475H20.1191V43.9518H41.1239C41.38 43.9518 41.5878 44.1595 41.5878 44.4157ZM9.50109 43.0454V36.8789C9.50109 36.3783 9.90694 35.9725 10.4075 35.9725H41.1234C41.3798 35.9725 41.5878 35.7647 41.5878 35.5082V1.40819C41.5878 1.15179 41.3799 0.943848 41.1234 0.943848H9.98934C8.01263 0.943848 6.41016 2.54632 6.41016 4.52304V43.4683C6.41016 45.445 8.01263 47.0475 9.98934 47.0475H16.3853V43.9518H10.4075C9.90694 43.9518 9.50109 43.546 9.50109 43.0454Z"
          fill="url(#paint2_linear_1484_161)"
        />
        <path
          d="M41.124 43.9517H20.1193L19.2974 47.0474H41.124C41.3802 47.0474 41.5879 46.8396 41.5879 46.5835V44.4155C41.5879 44.1593 41.3802 43.9517 41.124 43.9517Z"
          fill="url(#paint3_linear_1484_161)"
        />
        <path
          d="M41.1234 0.943848H37.7255V23.713C37.7255 28.5916 33.7706 32.5464 28.8921 32.5464H9.98953C8.01272 32.5464 6.41016 34.149 6.41016 36.1258V43.4681C6.41016 45.4449 8.01272 47.0475 9.98953 47.0475H18.0272L16.3853 43.9518H10.4075C9.90684 43.9518 9.50109 43.5459 9.50109 43.0454V36.8789C9.50109 36.3783 9.90694 35.9725 10.4075 35.9725H41.1234C41.3798 35.9725 41.5878 35.7647 41.5878 35.5082V1.40819C41.5878 1.15169 41.3799 0.943848 41.1234 0.943848Z"
          fill="url(#paint4_linear_1484_161)"
        />
        <path
          d="M29.1215 23.4218H26.7621C26.2957 23.4218 25.9175 23.0437 25.9175 22.5772V14.1219C25.9175 13.6555 25.5394 13.2773 25.0729 13.2773H18.8719C18.4055 13.2773 18.0273 13.6554 18.0273 14.1219V16.2743C18.0273 16.7407 18.4054 17.1189 18.8719 17.1189H21.2313C21.6978 17.1189 22.0759 17.497 22.0759 17.9635V22.5772C22.0759 23.0436 21.6978 23.4218 21.2313 23.4218H18.8719C18.4055 23.4218 18.0273 23.7998 18.0273 24.2663V26.4187C18.0273 26.8852 18.4054 27.2633 18.8719 27.2633H22.0759H25.9175H29.1215C29.5879 27.2633 29.9661 26.8852 29.9661 26.4187V24.2663C29.9661 23.7998 29.5879 23.4218 29.1215 23.4218Z"
          fill="url(#paint5_linear_1484_161)"
        />
        <path
          d="M23.994 10.8635C25.0561 10.8635 25.9172 10.0024 25.9172 8.94028C25.9172 7.87813 25.0561 7.01709 23.994 7.01709C22.9318 7.01709 22.0708 7.87813 22.0708 8.94028C22.0708 10.0024 22.9318 10.8635 23.994 10.8635Z"
          fill="url(#paint6_linear_1484_161)"
        />
        <path
          d="M12.7666 38.6323C12.7666 39.0324 13.0908 39.3565 13.4908 39.3565H39.2545C39.2844 38.8707 39.3504 38.3865 39.4525 37.9082H13.4908C13.0908 37.9081 12.7666 38.2323 12.7666 38.6323Z"
          fill="url(#paint7_linear_1484_161)"
        />
        <path
          d="M39.2545 40.5679H13.4908C13.0908 40.5679 12.7666 40.8921 12.7666 41.2921C12.7666 41.6921 13.0908 42.0163 13.4908 42.0163H39.4525C39.3504 41.5379 39.2844 41.0538 39.2545 40.5679Z"
          fill="url(#paint8_linear_1484_161)"
        />
        <path
          d="M16.3853 38.7234V48.7571C16.3853 48.9113 16.5617 48.9987 16.6844 48.9055L18.2522 47.7145L19.82 48.9055C19.9427 48.9987 20.1191 48.9113 20.1191 48.7571V38.7234C20.1191 38.6731 20.0783 38.6323 20.028 38.6323H16.4764C16.426 38.6323 16.3853 38.6731 16.3853 38.7234Z"
          fill="url(#paint9_linear_1484_161)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1484_161"
          x1="24.1066"
          y1="34.6978"
          x2="24.1066"
          y2="45.227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1484_161"
          x1="16.4016"
          y1="40.5542"
          x2="17.5633"
          y2="38.2578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4898F8" />
          <stop offset="1" stopColor="#8CE1F9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1484_161"
          x1="23.999"
          y1="0.943848"
          x2="23.999"
          y2="47.0475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1484_161"
          x1="30.4426"
          y1="43.9517"
          x2="30.4426"
          y2="47.0474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1484_161"
          x1="23.999"
          y1="0.943848"
          x2="23.999"
          y2="47.0475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#175EFC" />
          <stop offset="1" stopColor="#004CD3" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1484_161"
          x1="23.9967"
          y1="13.2773"
          x2="23.9967"
          y2="27.2633"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1484_161"
          x1="23.994"
          y1="7.01709"
          x2="23.994"
          y2="10.8635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1484_161"
          x1="26.1096"
          y1="37.9082"
          x2="26.1096"
          y2="39.3565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1484_161"
          x1="26.1096"
          y1="40.5679"
          x2="26.1096"
          y2="42.0163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54A5FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1484_161"
          x1="16.4016"
          y1="48.8942"
          x2="21.4156"
          y2="47.0379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4898F8" />
          <stop offset="1" stopColor="#8CE1F9" />
        </linearGradient>
        <clipPath id="clip0_1484_161">
          <rect width="48" height="48" fill="white" transform="translate(0 0.943848)" />
        </clipPath>
      </defs>
    </svg>
  );
};
