export const LandingArrowDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
      <path
        d="M22.5574 33.7054L21.4839 35.2842L21.4831 5.25879H20.6431L20.6439 35.096L19.7892 33.6088L19.0609 34.0271L21.0274 37.4497L23.2521 34.1779L22.5574 33.7054Z"
        fill="currentColor"
      />
    </svg>
  );
};
